/**
 * An action to fetch audit logs
 */
import SecureAxios from '../extensions/SecureAxios'

export const fetchAudits = async (searchOptions) => {
    let encodedKeyword = encodeURIComponent(searchOptions.keyword);
    let auditUrl = `/hana/admin/audits?keyword=${encodedKeyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        auditUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    if (searchOptions.customSearchOptions?.period) {
        auditUrl += `&period=${searchOptions.customSearchOptions.period}`
    }

    if (searchOptions.customSearchOptions?.startDate) {
        auditUrl += `&startDate=${searchOptions.customSearchOptions.startDate}`
    }

    if (searchOptions.customSearchOptions?.endDate) {
        auditUrl += `&endDate=${searchOptions.customSearchOptions.endDate}`
    }

    if (searchOptions.customSearchOptions?.username) {
        auditUrl += `&username=${searchOptions.customSearchOptions.username}`
    }

    const { data } = await SecureAxios.get(auditUrl)
    return data
}

/**
 * Generate Audit Report
 * @param searchOptions
 * @returns {Promise<any>}
 */
export const generateAuditReport = async (searchOptions) => {
    let encodedKeyword = encodeURIComponent(searchOptions.keyword);
    let auditReportUrl = `/hana/admin/audits/csv/report?keyword=${encodedKeyword}`

    if (searchOptions.customSearchOptions?.period) {
        auditReportUrl += `&period=${searchOptions.customSearchOptions.period}`
    }

    if (searchOptions.customSearchOptions?.startDate) {
        auditReportUrl += `&startDate=${searchOptions.customSearchOptions.startDate}`
    }

    if (searchOptions.customSearchOptions?.endDate) {
        auditReportUrl += `&endDate=${searchOptions.customSearchOptions.endDate}`
    }

    if (searchOptions.customSearchOptions?.username) {
        auditReportUrl += `&username=${searchOptions.customSearchOptions.username}`
    }

    const { data } = await SecureAxios.get(auditReportUrl)
    return data
}

export const fetchAuditTrainingData = async (searchOptions) => {
    let encodedKeyword = encodeURIComponent(searchOptions.keyword);
    let auditTrainingDataUrl = `/hana/admin/audits/training/data?keyword=${encodedKeyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        auditTrainingDataUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    if (searchOptions.customSearchOptions?.period) {
        auditTrainingDataUrl += `&period=${searchOptions.customSearchOptions.period}`
    }

    if (searchOptions.customSearchOptions?.startDate) {
        auditTrainingDataUrl += `&startDate=${searchOptions.customSearchOptions.startDate}`
    }

    if (searchOptions.customSearchOptions?.endDate) {
        auditTrainingDataUrl += `&endDate=${searchOptions.customSearchOptions.endDate}`
    }

    if (searchOptions.customSearchOptions?.username) {
        auditTrainingDataUrl += `&username=${searchOptions.customSearchOptions.username}`
    }

    if (searchOptions.customSearchOptions?.filteredByApprovedData) {
        auditTrainingDataUrl += `&filteredByApprovedData=${searchOptions.customSearchOptions.filteredByApprovedData}`
    }

    const { data } = await SecureAxios.get(auditTrainingDataUrl)
    return data
}

export const auditTrainingDataApproval = async (auditLogTrainingDataApproval) => {
    const { data } = await SecureAxios.put(`/hana/admin/audits/${auditLogTrainingDataApproval.id}/training/data/approval`, auditLogTrainingDataApproval, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
    return data
}

export const updateUsefulAuditForApproval = async (referenceId) => {
    const { data } = await SecureAxios.put(`/hana/audits/${referenceId}/like`)
    return data
}

/**
 * Generate Audit Training Data Report
 * @param searchOptions
 * @returns {Promise<any>}
 */
export const generateAuditTrainingDataReport = async (searchOptions) => {
    let encodedKeyword = encodeURIComponent(searchOptions.keyword);
    let auditTrainingDataUrl = `/hana/admin/audits/training/data/csv/report?keyword=${encodedKeyword}`

    if (searchOptions.customSearchOptions?.period) {
        auditTrainingDataUrl += `&period=${searchOptions.customSearchOptions.period}`
    }

    if (searchOptions.customSearchOptions?.startDate) {
        auditTrainingDataUrl += `&startDate=${searchOptions.customSearchOptions.startDate}`
    }

    if (searchOptions.customSearchOptions?.endDate) {
        auditTrainingDataUrl += `&endDate=${searchOptions.customSearchOptions.endDate}`
    }

    if (searchOptions.customSearchOptions?.username) {
        auditTrainingDataUrl += `&username=${searchOptions.customSearchOptions.username}`
    }

    if (searchOptions.customSearchOptions?.filteredByApprovedData) {
        auditTrainingDataUrl += `&filteredByApprovedData=${searchOptions.customSearchOptions.filteredByApprovedData}`
    }

    const { data } = await SecureAxios.get(auditTrainingDataUrl)
    return data
}
