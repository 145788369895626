import React  from 'react'
import { Box } from '@mui/material'
import DataGridFilter, { SearchOptionsProp } from '../../../components/DataGridFilter'
import { fetchAssignees } from '../../../actions/user'
import { HanaAssignee } from '../../../interfaces/AssigneeType'
import Header from '../../../components/Header'
import AdminAssigneeDetail from './detail'

function AdminAssignees() {

    const expandRow = (row: HanaAssignee) => (
        <AdminAssigneeDetail isNew={false} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchAssignees(searchOptions)
    }

    const columns = [
        {
            dataField: 'username',
            text: 'Username',
            sort: true,
        },
        {
            dataField: 'displayName',
            text: 'Display Name',
            sort: true,
        }
    ]

    return (
        <Box m='20px'>
            <Header title='Assignee' />

            <DataGridFilter
                keyField='id'
                useQueryKey={`admin-assignees`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={2}
                expandRow={expandRow}
            />
        </Box>
    )
}

export default AdminAssignees