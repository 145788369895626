import React from 'react'
import { Box } from '@mui/material';
import * as Diff from 'diff';
import { html as diff2html } from 'diff2html';
import 'diff2html/bundles/css/diff2html.min.css';
import { markdownToHtml } from './parser/Parser';
import he from 'he';
import DOMPurify from 'dompurify';

interface MarkdownDiffViewerProps {
    oldMarkdown: string;
    newMarkdown: string;
}

// Function to remove <ins> and <del> tags from HTML
const removeHighlightingTags = (html: string) => {
    return html
        .replace(/<\/?ins[^>]*>/g, '') // Remove <ins> and </ins> tags
        .replace(/<\/?del[^>]*>/g, ''); // Remove <del> and </del> tags
};

const MarkdownDiffViewer: React.FC<MarkdownDiffViewerProps> = ({ oldMarkdown, newMarkdown }) => {
    const oldHtml = markdownToHtml(oldMarkdown);
    const newHtml = markdownToHtml(newMarkdown);

    // Generate the unified diff using the 'diff' library
    const diff = Diff.createPatch('diff', oldHtml, newHtml);

    // Convert diff to HTML using diff2html
    // @ts-ignore
    let diffHtml = diff2html(diff, { inputFormat: 'diff', showFiles: false, highlight: false, matching: 'words' });

    // Remove <ins> and <del> tags from the diff HTML
    diffHtml = removeHighlightingTags(diffHtml);

    // Decode any HTML entities within the diff
    diffHtml = he.decode(diffHtml);

    // Sanitize HTML to ensure it's safe and properly formatted
    diffHtml = DOMPurify.sanitize(diffHtml, { USE_PROFILES: { html: true } });

    diffHtml += `<style>
        .d2h-code-line-ctn {
            white-space: pre-wrap;
        }
        /* Hide line numbers */
        .d2h-code-linenum {
            display: none;
        }
        .d2h-code-line {
            padding-left: 5px !important;
            width: calc(100% - 1em);
        }
        .d2h-code-linenumber {
            display: none;
        }
        /* Highlight the background of added and removed lines */
        .d2h-del {
            background-color: #E9AEAE !important;
            outline-color: #E9AEAE !important;
        }
        .d2h-ins {
            background-color: #B4E2B4 !important;
            outline-color: #B4E2B4 !important;
        }
    </style>`;

    return (
        <Box style={{ overflowY: 'auto', overflowX: 'auto', maxHeight: 'auto' }} dangerouslySetInnerHTML={{ __html: diffHtml }} />
    );
};

export default MarkdownDiffViewer;