import { Box, IconButton, Menu, MenuItem, useTheme } from '@mui/material'
import { useContext, useState } from 'react'
import { ColorModeContext, tokens } from '../../theme'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { AuthToken, constructAuthRedirectUrl, signout, transit } from '../../actions/auth'
import AutocompleteExt from '../../components/Autocomplete'
import { connect } from 'react-redux'

const Topbar = (props: { user: AuthToken }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const colorMode = useContext(ColorModeContext)
    const [profileAnchorEl, setProfileAnchorEl] = useState(null)
    const { user } = props;

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {/* Switch Bar */}
            <Box
                display="flex"
                borderRadius="3px"
                sx={{
                    backgroundColor: colors.primary[400],
                    width: `auto`,

                    "& .MuiFilledInput-root .MuiFilledInput-input": {
                        width: `100% !important`
                    },
                }}
            >
                <AutocompleteExt
                    name='dashboards'
                    multiSelection={false}
                    label='Switch Dashboard'
                    options={user?.user?.dashboards?.map(dashboard => {
                        return {
                            label: dashboard.name,
                            value: dashboard.url
                        }
                    })}
                    onSelect={async (v) => {
                        const data = await transit(user)
                        const authTransitRedirectUrl = constructAuthRedirectUrl(data.code, v + "/transit")
                        window.location.replace(authTransitRedirectUrl)
                    }}
                    editable={user?.user?.dashboards && user.user.dashboards.length > 0}
                />
            </Box>

            {/* Icons */}

            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === 'dark' ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton>
                <IconButton
                    onClick={(e: any) => setProfileAnchorEl(e.currentTarget)}
                >
                    <PersonOutlinedIcon />
                </IconButton>
                <Menu
                    id="profile-menu"
                    anchorEl={profileAnchorEl}
                    keepMounted
                    open={Boolean(profileAnchorEl)}
                    onClose={() => setProfileAnchorEl(null)}
                >
                    <MenuItem onClick={() => signout()} key="Logout">
                        <ExitToAppIcon /> &nbsp; &nbsp; Logout
                    </MenuItem>
                </Menu>
            </Box>
        </Box>
    )
}
/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(Topbar)
