import React, { FunctionComponent, useState } from 'react'
import { Box, TextField } from '@mui/material'
import {
    AuditLogTrainingData,
    AuditLogTrainingDataApprovalRequest,
    AuditLogTrainingDataWrapper,
} from '../../../../../../interfaces/AuditType'
import TextareaAutosizeExt from '../../../../../../components/TextareaAutosize'
import ButtonExt from '../../../../../../components/ButtonExt'
import { useMutation } from 'react-query'
import { ApiError } from '../../../../../../interfaces/ErrorType'
import { auditTrainingDataApproval } from '../../../../../../actions/audit'
import { v4 as uuidv4 } from 'uuid'

const AdminPromptQueryReviewDetail: FunctionComponent<AuditLogTrainingDataWrapper> = ({
                                                                                          wrapper,
                                                                                          callback
                                                                                      }) => {
    const [auditLogTrainingData, setAuditLogTrainingData] = useState<AuditLogTrainingData>(wrapper!!)

    const auditTrainingDataApprovalMutation = useMutation<AuditLogTrainingData, ApiError, AuditLogTrainingDataApprovalRequest>(
        auditTrainingDataApproval,
    )

    const onApproval = (values: AuditLogTrainingDataApprovalRequest) => {
        auditTrainingDataApprovalMutation.mutate(values, {
            onSuccess: async (data) => {
                setAuditLogTrainingData(data)

                if (callback) {
                    callback(uuidv4())
                }
            }
        })
    }

    /**
     * Page containing admin prompt query review details
     */
    return (
        <Box m="20px">
            <Box
                display="grid"
                gap="30px"
            >
                <TextField
                    variant="filled"
                    type="text"
                    label="Id"
                    value={auditLogTrainingData.id}
                    name="id"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Service"
                    value={auditLogTrainingData.service}
                    name="service"
                />

                <TextareaAutosizeExt
                    label="Query"
                    value={auditLogTrainingData.query}
                    name="query"
                    minRows={10}
                    maxRows={10}
                />

                <TextareaAutosizeExt
                    label="Prompt Request"
                    value={auditLogTrainingData.promptRequest}
                    name="promptRequest"
                    minRows={10}
                    maxRows={10}
                />

                <TextareaAutosizeExt
                    label="Result"
                    value={auditLogTrainingData.result}
                    name="result"
                    minRows={30}
                    maxRows={30}
                />
            </Box>

            <Box
                display="flex"
                justifyContent="end"
                mt="20px"
                gap="20px"
            >
                {!auditLogTrainingData.approved && (
                    <>
                        <ButtonExt
                            type="button"
                            value={
                                auditTrainingDataApprovalMutation.isLoading
                                    ? 'Approving...'
                                    : 'Approve'
                            }
                            onClickEvent={() => {
                                onApproval({
                                    id: auditLogTrainingData.id,
                                    approved: true,
                                    rejected: false,
                                })
                            }}
                            disabled={
                                auditTrainingDataApprovalMutation.isLoading ||
                                auditTrainingDataApprovalMutation.isLoading
                            }
                        />

                        {!auditLogTrainingData.rejected && (
                            <ButtonExt
                                type="button"
                                value={
                                    auditTrainingDataApprovalMutation.isLoading
                                        ? 'Rejecting...'
                                        : 'Reject'
                                }
                                onClickEvent={() => {
                                    onApproval({
                                        id: auditLogTrainingData.id,
                                        approved: false,
                                        rejected: true,
                                    })
                                }}
                                disabled={
                                    auditTrainingDataApprovalMutation.isLoading ||
                                    auditTrainingDataApprovalMutation.isLoading
                                }
                            />
                        )}
                    </>
                )}
            </Box>
        </Box>
    )
}

export default AdminPromptQueryReviewDetail
