import { Box } from '@mui/material'
import Header from '../../components/Header'
import React from 'react'
import MediaLibrary from '../../components/MediaLibrary'
import { Tabs } from 'antd'

function ManageMediaLibrary() {

    return (
        <Box m='20px'>
            <Header title='Manage Media Libraries' />

            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Image" key="1">
                    <MediaLibrary mediaType="MEDIA" disabledAssign={true} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Video" key="2">
                    <MediaLibrary mediaType="VIDEO" disabledAssign={true} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Audio" key="3">
                    <MediaLibrary mediaType="AUDIO" disabledAssign={true} />
                </Tabs.TabPane>
            </Tabs>

        </Box>
    )
}

export default ManageMediaLibrary