import React, { FunctionComponent, useMemo, useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { AuthToken } from '../actions/auth'
import { connect } from 'react-redux'
import { htmlToMarkdown } from './parser/Parser'
import {
    assist, deleteTemplate, deleteTemplateCategory,
    getTemplateContent,
    getTemplates, moveTemplate, moveTemplateCategory, renameTemplate,
    renameTemplateCategory,
    saveTemplate,
    saveTemplateCategory,
} from '../actions/tinymce'
import { FormHelperText } from '@mui/material'
import { v4 as uuidv4 } from 'uuid';

export interface TinyMCEProps {
    user: AuthToken
    htmlValue: string;
    onChangeEvent: (valueChange: TinyMCEValueChange) => void;
    onBlurEvent?: (event: any) => void;
    error?: boolean | undefined
    helperText?: React.ReactNode
    editable?: boolean;
}

export interface TinyMCEValueChange {
    htmlValue: string;
    markdownValue: string;
}

const TinyMCE: FunctionComponent<TinyMCEProps> = ({
                                                      user,
                                                      htmlValue,
                                                      onChangeEvent,
                                                      onBlurEvent,
                                                      error,
                                                      helperText,
                                                      editable,
                                                  }) => {
    const editorRef = useRef<any>(null);
    const editorKey = useMemo(() => uuidv4(), [])

    const plugin = 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss markdown preview'
    const toolbar = 'undo redo | preview | addcomment showcomments | aidialog aishortcuts | inserttemplate addtemplate | blocks | bold italic | link image media | numlist bullist | removeformat | spellcheckdialog typography'
    const contextmenu = 'advtemplate'

    // TODO: consider customizing the AI shortcuts to better fit the use case
    const aiShortCuts = [
        { title: 'Summarize content', prompt: 'Provide the key points and concepts in this content in a succinct summary using British writing practices.', selection: true },
        { title: 'Improve writing', prompt: 'Rewrite this content with no spelling mistakes, proper grammar, and with more descriptive language, using best British writing practices without losing the original meaning.', selection: true },
        { title: 'Simplify language', prompt: 'Rewrite this content with simplified language and reduce the complexity of the writing using British writing practices, so that the content is easier to understand.', selection: true },
        { title: 'Expand upon', prompt: 'Expand upon this content with descriptive language and more detailed explanations using British writing practices, to make the writing easier to understand and increase the length of the content.', selection: true },
        { title: 'Trim content', prompt: 'Remove any repetitive, redundant, or non-essential writing in this content using British writing practices without changing the meaning or losing any key information.', selection: true },
        { title: 'Change tone', subprompts: [
                { title: 'Professional', prompt: 'Rewrite this content using polished, formal, and respectful British language to convey professional expertise and competence.', selection: true },
                { title: 'Casual', prompt: 'Rewrite this content with British casual, informal language to convey a casual conversation with a real person.', selection: true },
                { title: 'Direct', prompt: 'Rewrite this content with direct British language using only the essential information.', selection: true },
                { title: 'Confident', prompt: 'Rewrite this content using compelling, optimistic British language to convey confidence in the writing.', selection: true },
                { title: 'Friendly', prompt: 'Rewrite this content using friendly, comforting British language, to convey understanding and empathy.', selection: true },
            ] },
        { title: 'Change style', subprompts: [
                { title: 'Business', prompt: 'Rewrite this content as a business professional with British formal language.', selection: true },
                { title: 'Legal', prompt: 'Rewrite this content as a legal professional using valid British legal terminology.', selection: true },
                { title: 'Journalism', prompt: 'Rewrite this content as a journalist using engaging British language to convey the importance of the information.', selection: true },
                { title: 'Medical', prompt: 'Rewrite this content as a medical professional using valid British medical terminology.', selection: true },
                { title: 'Poetic', prompt: 'Rewrite this content as a poem using British poetic techniques without losing the original meaning.', selection: true },
            ] },
        { title: 'Conversion', subprompts: [
                { title: 'Plain to HTML', prompt: 'Add appropriate line spacing after headings, lists, and paragraphs; use <h3><strong>Headings</strong></h3> for main headings; format lists with <ul><li> tags and bold keywords, like <li><strong>Keyword.</strong> Description.</li>; ensure British English and retain original information.', selection: true },
                { title: 'Markdown to HTML', prompt: 'Convert this content from a mix of Markdown and HTML to proper HTML format without changing the meaning or losing any key information.', selection: true },
            ] },
    ]

    async function makeAiRequestWithCancellation(request: any, assistFunction: any, signal: any) {
        // Create an AbortController instance
        const controller = new AbortController();
        const { signal: fetchSignal } = controller;

        // Attach the external signal to the fetch request for cancellation
        signal.addEventListener('abort', () => controller.abort());

        try {
            // Pass the signal to the assist function (assuming it supports it)
            const response = await assistFunction(request, { signal: fetchSignal });

            // Handle the response
            return response.result;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    const onInternalBlurEvent = async (event: any) => {
        if (onBlurEvent) {
            onBlurEvent(event)
        }
    }

    return (
        <FormHelperText onBlur={onInternalBlurEvent} error={error}>
            {user?.user?.role === 'ADMIN' && (
                <Editor
                    key={editorKey}
                    apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                    init={{
                        plugins: `${plugin}`,
                        toolbar: `${toolbar}`,
                        contextmenu: `${contextmenu}`,
                        min_height: 800,
                        menubar: false, // Hide the menubar completely
                        branding: false,  // This will remove the "Powered by TinyMCE" branding
                        spellchecker_language: 'en_GB',
                        tinycomments_mode: 'embedded',
                        tinycomments_author: `${user?.user?.username} (${user?.displayName})`,
                        tinycomments_highlighting: true, // Enable comment highlighting
                        tinycomments_can_resolve: (req: any, done: any, fail: any) => {
                            done({
                                canResolve: true
                            });
                        },
                        // Setup all the default sidebar panels
                        setup: (editor: any) => {
                            editorRef.current = editor;
                            // editor.on('init', function () {
                            //     // Wait for the editor to fully initialize
                            //     setTimeout(() => {
                            //         // Open the comments sidebar
                            //         editor.execCommand('ToggleSidebar', false, 'showcomments');
                            //     }, 1000); // Delay to ensure the editor is fully loaded
                            // });
                        },
                        // Setup the advtemplate plugin
                        advtemplate_list: async () => {
                            const templates = await getTemplates();
                            return templates
                        },
                        advtemplate_get_template: async (id: string) => {
                            const templateContent = await getTemplateContent(id)
                            return templateContent
                        },
                        advtemplate_create_category: async (title: string) => {
                            const categoryId = await saveTemplateCategory(title)
                            return categoryId
                        },
                        advtemplate_create_template: async (title: string, content: string, categoryId: string) => {
                            const templateId = await saveTemplate(title, content, categoryId)
                            return templateId
                        },
                        advtemplate_rename_category: async (id: string, title: string) => {
                            await renameTemplateCategory(id, title)
                            return {}
                        },
                        advtemplate_rename_template: async (id: string, title: string) => {
                            await renameTemplate(id, title)
                            return {}
                        },
                        advtemplate_delete_template: async (id: string) => {
                            await deleteTemplate(id)
                            return {}
                        },
                        advtemplate_delete_category: async (id: string) => {
                            await deleteTemplateCategory(id)
                            return {}
                        },
                        advtemplate_move_template: async (templateId: string, newCategoryId: string) => {
                            await moveTemplate(templateId, newCategoryId)
                            return {}
                        },
                        advtemplate_move_category_items: async (oldCategoryId: string, newCategoryId: string) => {
                            await moveTemplateCategory(oldCategoryId, newCategoryId)
                            return {}
                        },
                        // Setup the AI shortcuts plugin
                        ai_shortcuts: aiShortCuts,
                        // Handled the AI request to the server
                        ai_request: (request: any, respondWith: any) => {
                            return respondWith.string(async (signal: AbortSignal) => {
                                return makeAiRequestWithCancellation(request, assist, signal);
                            });
                        }
                    }}
                    value={htmlValue ? htmlValue : ''}
                    onEditorChange={(content) => {
                        const markdown = htmlToMarkdown(content)
                        onChangeEvent({ htmlValue: content, markdownValue: markdown })
                    }}
                    disabled={!editable}
                />
            )}

            {user?.user?.role === 'USER' && (
                <Editor
                    key={editorKey}
                    apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                    init={{
                        plugins: `${plugin}`,
                        toolbar: `${toolbar}`,
                        contextmenu: `${contextmenu}`,
                        min_height: 800,
                        menubar: false, // Hide the menubar completely
                        branding: false,  // This will remove the "Powered by TinyMCE" branding
                        spellchecker_language: 'en_GB',
                        tinycomments_mode: 'embedded',
                        tinycomments_author: `${user?.user?.username} (${user?.displayName})`,
                        tinycomments_highlighting: true, // Enable comment highlighting
                        tinycomments_can_resolve: (req: any, done: any, fail: any) => {
                            done({
                                canResolve: true
                            });
                        },
                        // Setup all the default sidebar panels
                        setup: (editor: any) => {
                            editorRef.current = editor;
                            // editor.on('init', function () {
                            //     // Wait for the editor to fully initialize
                            //     setTimeout(() => {
                            //         // Open the comments sidebar
                            //         editor.execCommand('ToggleSidebar', false, 'showcomments');
                            //     }, 1000); // Delay to ensure the editor is fully loaded
                            // });
                        },
                        // Setup the advtemplate plugin
                        advtemplate_list: async () => {
                            const templates = await getTemplates();
                            return templates
                        },
                        advtemplate_get_template: async (id: string) => {
                            const templateContent = await getTemplateContent(id)
                            return templateContent
                        },
                        advtemplate_create_template: async (title: string, content: string, categoryId: string) => {
                            const templateId = await saveTemplate(title, content, categoryId)
                            return templateId
                        },
                        advtemplate_rename_template: async (id: string, title: string) => {
                            try {
                                await renameTemplate(id, title)
                                return {}
                            } catch (error: any) {
                                const message = error?.response?.data?.message || "An unknown error occurred.";
                                throw new Error(message);
                            }
                        },
                        advtemplate_delete_template: async (id: string) => {
                            try {
                                await deleteTemplate(id);
                                return {};
                            } catch (error: any) {
                                const message = error?.response?.data?.message || "An unknown error occurred.";
                                throw new Error(message);
                            }
                        },
                        advtemplate_move_template: async (templateId: string, newCategoryId: string) => {
                            try {
                                await moveTemplate(templateId, newCategoryId)
                                return {}
                            } catch (error: any) {
                                const message = error?.response?.data?.message || "An unknown error occurred.";
                                throw new Error(message);
                            }
                        },
                        // Setup the AI shortcuts plugin
                        ai_shortcuts: aiShortCuts,
                        // Handled the AI request to the server
                        ai_request: (request: any, respondWith: any) => {
                            return respondWith.string(async (signal: AbortSignal) => {
                                return makeAiRequestWithCancellation(request, assist, signal);
                            });
                        }
                    }}
                    value={htmlValue ? htmlValue : ''}
                    onEditorChange={(content) => {
                        const markdown = htmlToMarkdown(content)
                        onChangeEvent({ htmlValue: content, markdownValue: markdown })
                    }}
                    disabled={!editable}
                />
            )}

            {helperText}
        </FormHelperText>
    );
};


/**
 * Connect and retrieve the current user through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(TinyMCE)