import SecureAxios from "../extensions/SecureAxios";

/**
 * An action to fetch development stages from neuro api
 */
export const fetchDevelopmentStages = async () => {
    const {data} = await SecureAxios.get(`/development/stage?keyword=&page=1&size=100&sort=weekFrom&sortDir=ASC`)
    return data.data.content
}

/**
 * Calculate due by month and selected development stage
 *
 * @param param
 * @returns {*}
 */
export const calculateDueByMonthAndSelectedDevelopmentStage = async (param) => {
    const {data} = await SecureAxios.get(`/uom/convert/month/to/due?month=${param.month}&developmentStageId=${param.developmentStageId}`)
    return data.data
}

/**
 * Calculate due by week and selected development stage
 *
 * @param param
 * @returns {*}
 */
export const calculateDueByWeekAndSelectedDevelopmentStage = async (param) => {
    const {data} = await SecureAxios.get(`/uom/convert/week/to/due?week=${param.week}&developmentStageId=${param.developmentStageId}`)
    return data.data
}

