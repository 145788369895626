import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Box, InputLabel, useTheme } from '@mui/material'
import { Checkbox } from '@mui/material'
import { tokens } from '../theme'

const CheckboxExt: FunctionComponent<CheckboxProp> = ({
    name,
    value,
    label,
    required = false,
    editable = true,
    onBlurEvent,
    onChange,
    onChangeEvent,
}) => {
    const uuid = useMemo(() => uuidv4(), [])
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const onInternalBlurEvent = async (event: any) => {
        if (onBlurEvent) {
            onBlurEvent(event)
        }
    }

    const onInternalChangeEvent = async (event: any) => {
        if (onChange) {
            onChange(!value)
        }

        if (onChangeEvent) {
            onChangeEvent(event)
        }
    }

    return (
        <Box
            display="grid"
            sx={{
                '& > div': { gridColumn: ' span 2' },
                '& .MuiCheckbox-root': {
                    color: `${colors.greenAccent[200]} !important`,
                },
            }}
        >
            {label && (
                <InputLabel
                    style={{
                        fontSize: `x-small`,
                        paddingLeft: `15px`,
                        paddingBottom: `0px`,
                    }}
                >
                    {label}
                </InputLabel>
            )}
            <Checkbox
                style={{ display: `block`, paddingLeft: `15px` }}
                key={uuid}
                name={name}
                checked={value}
                onBlur={onInternalBlurEvent}
                onChange={onInternalChangeEvent}
                disabled={!editable}
                required={required}
            />
        </Box>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface CheckboxProp {
    name: string
    label?: string | undefined
    value: boolean | undefined
    required?: boolean
    editable?: boolean
    onChange?: (v: boolean) => void // Handle event upon onclick
    onChangeEvent?: (event: any) => void // Handle event upon onclick
    onBlurEvent?: (event: any) => void // Handle event upon onblur
}

export default CheckboxExt
