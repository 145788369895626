import SecureAxios from '../extensions/SecureAxios'

export async function contentAutoQaMerging(content) {
    const requestBody = {
        content: content.body,
    }

    const { data } = await SecureAxios.post(`/content/auto/qa/content/merging`, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data
}

export async function contentAutoQaMedicalMerging(content) {
    const requestBody = {
        originalContent: content.previousBody,
        medicalChanges: content.body,
    }

    const { data } = await SecureAxios.post(`/content/auto/qa/medical/merging`, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data
}