import {
    Box,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Stack,
    Typography,
    useTheme,
    TextField,
    Tooltip,
    CircularProgress,
    Checkbox,
} from '@mui/material'
import React, { useState } from 'react'
import { tokens } from '../theme'
import { useMutation } from 'react-query'
import { GenerateImageForm } from '../interfaces/GenerateImageFormType'
import DownloadIcon from '@mui/icons-material/Download'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ExpandableImage from './ExpandableImage'
import ButtonExt from './ButtonExt'
import { assignImage, downloadImage, generateImage, generatePromptScript } from '../actions/content'

interface GenerateImageProps {
    contentSubmissionRequestId: string
    mediaType?: string
    onClose?: any
    onChangeEvent?: (mediaType: string, key: string, promptUsed?: string) => void
}

const GenerateImage = ({
                           contentSubmissionRequestId,
                           mediaType,
                           onClose,
                           onChangeEvent,
                       }: GenerateImageProps) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const [generateImageForm, setGenerateImageForm] =
        useState<GenerateImageForm>({
            fileFormat: '',
            imagePrompt: '',
            model: 'dall-e-3',
            style: 'vivid',
            prompt: '',
            quantity: '1',
            styleConsistencyTarget: 'background',
            artStyle: 'cartoon',
        })
    const [modifiedPrompt, setModifiedPrompt] = useState<string>('')
    const [generatedImages, setGeneratedImages] = useState<Array<string>>([])
    const [imageGenerationLoading, setImageGenerationLoading] =
        useState<boolean>(false)
    const [selectGeneratedImage, setSelectGeneratedImage] = useState<string>('')
    const [imageAssignmentLoading, setImageAssignmentLoading] =
        useState<boolean>(false)
    const [generateImageError, setGenerateImageError] = useState<string>('')

    const onDefaultMediaPromptScriptGeneration = async (
        contentSubmissionId: string,
        type?: any
    ) => {
        return generatePromptScript(contentSubmissionId)
    }

    const generateDefaultPrompt = useMutation(
        onDefaultMediaPromptScriptGeneration
    )

    const handleChange = (e: any) => {
        const { name, value } = e.target
        const updatedValues = {
            ...generateImageForm,
            [name]: value,
        }
        setGenerateImageForm(updatedValues)
    }

    const generateImages = async () => {
        console.log('Data: ', generateImageForm)
        try {
            setImageGenerationLoading(true)
            setGeneratedImages([])
            const requestBody = { ...generateImageForm }
            const response = await generateImage(requestBody)
            setGeneratedImages(response.imageUrls)
            setModifiedPrompt(response.modifiedPrompt)
            setImageGenerationLoading(false)
            setGenerateImageError('')
        } catch (err: any) {
            console.log('Error: ', err)
            if (err.response.status === 422) {
                setGenerateImageError(
                    'The image generation has failed due to Content Policy Violation. Please modify the prompt that complies with the policy.'
                )
            } else {
                setGenerateImageError(err.response.data.message)
            }
            setImageGenerationLoading(false)
        }
    }

    const handleImageAssignment = async () => {
        try {
            setImageAssignmentLoading(true)
            const data = await assignImage(contentSubmissionRequestId, mediaType, selectGeneratedImage, modifiedPrompt)

            if (onChangeEvent && mediaType && data.key ) {
                onChangeEvent(mediaType, data.key, modifiedPrompt)
            }

            setImageAssignmentLoading(false)
            onClose('')
        } catch (e) {
            console.log('Image Assignment Error: ', e)
            setImageAssignmentLoading(false)
        }
    }

    const handleDownloadGeneratedImage = async (imageUrl: string) => {
        try {
            await downloadImage(imageUrl)
        } catch (error) {
            console.error('Failed to download image:', error);
        }
    };

    return (
        <Box width={800}>
            <Stack direction={'row'} spacing={2}>
                <CustomFormControl
                    name="quantity"
                    value={generateImageForm.quantity}
                    onChange={handleChange}
                    label={'Number of Images'}
                    options={[1, 2, 3]}
                    type={'SELECTABLE'}
                />
                <CustomFormControl
                    name="model"
                    value={generateImageForm.model}
                    onChange={handleChange}
                    label={'Model'}
                    options={['dall-e-3', 'stable-diffusion-xl']}
                    type={'SELECTABLE'}
                />
                {generateImageForm.model === 'dall-e-3' && (
                    <CustomFormControl
                        name="style"
                        value={generateImageForm.style}
                        onChange={handleChange}
                        label={'Visual Style'}
                        options={['vivid', 'natural']}
                        type={'SELECTABLE'}
                    />
                )}
                <CustomFormControl
                    name="artStyle"
                    value={generateImageForm.artStyle}
                    onChange={handleChange}
                    label={'Art Style'}
                    options={['cartoon', 'human']}
                    type={'SELECTABLE'}
                />
            </Stack>

            <Box mt={2}>
                <Typography
                    paddingY={1}
                    sx={{ display: 'flex', alignItems: 'center', gap: 1.2 }}
                >
                    Image Prompt
                    <Box sx={{ marginLeft: 'auto' }}>
                        {(generateDefaultPrompt.isLoading) ? (
                            <CircularProgress color={'secondary'} size={'20px'} />
                        ) : (
                            !imageGenerationLoading && (
                                <ButtonExt
                                    type="button"
                                    style={{ marginRight: '1em', marginBottom: '1em' }}
                                    value="Generate Prompt"
                                    disabled={generateDefaultPrompt.isLoading}
                                    onClickEvent={() => {
                                        setGenerateImageError('')
                                        generateDefaultPrompt.mutate(contentSubmissionRequestId, {
                                            onSuccess: (data) => {
                                                setGenerateImageForm((prev) => ({
                                                    ...prev,
                                                    prompt: data.prompt,
                                                }))
                                            },
                                        })
                                    }}
                                />
                            )
                        )}
                    </Box>
                </Typography>
                <Box style={{color: `red`, marginBottom: `1em`}}>
                    Note: The semi-automated prompt generated from the content source may not always perfectly match your needs. It's important to review and modify the prompt, or use your own, to ensure it meets your requirements before generating an image.
                </Box>
                <TextField
                    id="outlined-multiline-flexible"
                    fullWidth
                    multiline
                    minRows={6}
                    maxRows={6}
                    variant="outlined" // Add this variant prop
                    style={{border: '1px solid #ccc', borderRadius: '4px'}} // Add this style prop for border
                    value={
                        generateDefaultPrompt.isLoading
                            ? `Generating prompt ....`
                            : generateImageForm.prompt
                    }
                    onChange={(e) => {
                        if (!generateDefaultPrompt.isLoading) {
                            setGenerateImageForm({
                                ...generateImageForm,
                                prompt: e.target.value,
                            })
                        }
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent:
                            generateImageError && !imageGenerationLoading
                                ? 'space-between'
                                : 'flex-end',
                        mt: 2,
                    }}
                >
                    {imageGenerationLoading && (
                        <Box style={{color: `red`, margin: `1em`}} display={'flex'} gap={1}>
                            Note: The image generation process may take a few minutes. Please be patient.
                        </Box>
                    )}
                    {generateImageError && !imageGenerationLoading && (
                        <Box display={'flex'} gap={1}>
                            <ErrorOutlineIcon color="error" />
                            <Typography
                                width={'80%'}
                                fontSize={12.5}
                                color={'error'}
                            >
                                {generateImageError}
                            </Typography>
                        </Box>
                    )}
                    {imageGenerationLoading ? (
                        <CircularProgress color={'secondary'} size={'35px'} />
                    ) : (
                        <ButtonExt
                            type="button"
                            style={{ marginRight: '1em' }}
                            value="Generate Image"
                            disabled={
                                generateDefaultPrompt.isLoading ||
                                !generateImageForm.prompt.trim()
                            }
                            onClickEvent={generateImages}
                        />
                    )}
                </Box>
            </Box>

            {generatedImages.length > 0 && (
                <>
                    <Typography
                        textAlign={'left'}
                        mt={3}
                        fontSize={23}
                        sx={{ textDecoration: 'underline' }}
                    >
                        Images for review
                    </Typography>
                    <Box
                        mt={3}
                        display={'flex'}
                        gap={3}
                        justifyContent={'left'}
                        flexWrap={'wrap'}
                    >
                        {generatedImages?.map((image, index) => {
                            return (
                                <>
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        borderRadius={1.5}
                                        border={'1px solid grey'}
                                    >
                                        <Box
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'space-around'}
                                            height={27}
                                            borderBottom={'1px solid grey'}
                                        >
                                            <Box>
                                                <Checkbox
                                                    checked={
                                                        selectGeneratedImage ===
                                                        image
                                                    }
                                                    onChange={() =>
                                                        setSelectGeneratedImage(
                                                            image
                                                        )
                                                    }
                                                    sx={{ padding: 0 }}
                                                    color={'secondary'}
                                                />
                                            </Box>
                                            <Box
                                                display={'flex'}
                                                alignItems={'center'}
                                                justifyContent={'center'}
                                            >
                                                <DownloadIcon
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() =>
                                                        handleDownloadGeneratedImage(
                                                            image
                                                        )
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                        <ExpandableImage
                                            style={{
                                                objectFit: 'contain',
                                                width: 230,
                                            }}
                                            url={image}
                                            name={`Image ${index}`}
                                        />
                                    </Box>
                                </>
                            )
                        })}
                    </Box>
                    <Box sx={{ float: 'right' }} mt={2}>
                        {imageAssignmentLoading ? (
                            <CircularProgress
                                color={'secondary'}
                                size={'32px'}
                            />
                        ) : (
                            <ButtonExt
                                type="button"
                                value="Assign"
                                disabled={!selectGeneratedImage}
                                onClickEvent={handleImageAssignment}
                            />
                        )}
                    </Box>
                </>
            )}
        </Box>
    )
}

export default GenerateImage

interface CustomFormControlProps {
    label: string
    tooltip?: string
    options?: Array<string | number>
    type: 'SELECTABLE' | 'INPUT'
    value: string
    onChange: any
    name: string
}

const CustomFormControl = ({
                               label,
                               options,
                               tooltip,
                               type,
                               value,
                               onChange,
                               name,
                           }: CustomFormControlProps) => {
    return (
        <>
            {type === 'SELECTABLE' && (
                <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">
                        {label}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="Age"
                        onChange={onChange}
                        name={name}
                    >
                        {options?.map((option, index) => (
                            <MenuItem key={index} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            {type === 'INPUT' && (
                <Tooltip title={tooltip} arrow>
                    <TextField
                        placeholder={label}
                        id="outlined-start-adornment"
                        fullWidth
                        value={value}
                        onChange={onChange}
                        name={name}
                    />
                </Tooltip>
            )}
        </>
    )
}
