import { useQuery, UseQueryOptions, QueryKey } from 'react-query'
import { UseQueryResult } from 'react-query/types/react/types'
import { QueryFunction } from 'react-query/types/core/types'
import { removeToken } from './SecureAxios'
import { ApiError } from '../interfaces/ErrorType'

export const useAuthQuery = <
    TQueryFnData = unknown,
    TError extends ApiError = ApiError,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(
    options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) => {
    const query = useQuery<TQueryFnData, TError, TData, TQueryKey>(options)
    validate(query)
    return query
}

export const useAuthQueryWithQueryKey = <
    TQueryFnData = unknown,
    TError extends ApiError = ApiError,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(
    queryKey: TQueryKey,
    options?: Omit<
        UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
        'queryKey'
    >
) => {
    const query = useQuery<TQueryFnData, TError, TData, TQueryKey>(
        queryKey,
        options
    )
    validate(query)
    return query
}

export const useAuthQueryWithQueryFunction = <
    TQueryFnData = unknown,
    TError extends ApiError = ApiError,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(
    queryKey: TQueryKey,
    queryFn: QueryFunction<TQueryFnData, TQueryKey>,
    options?: Omit<
        UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
        'queryKey' | 'queryFn'
    >
) => {
    const query = useQuery<TQueryFnData, TError, TData, TQueryKey>(
        queryKey,
        queryFn,
        options
    )
    validate(query)
    return query
}

const validate = (query: UseQueryResult<any, ApiError>) => {
    if (query?.error?.response?.status === 401) {
        removeToken()
        window.location.reload()
    }
}
