import React  from 'react'
import { Box } from '@mui/material'
import { AdminPromptType } from '../../../interfaces/PromptType'
import DataGridFilter, { SearchOptionsProp } from '../../../components/DataGridFilter'
import Header from '../../../components/Header'
import { fetchAdminPrompt } from '../../../actions/prompt'
import AdminPromptDetail from './detail'

function AdminPrompt() {

    const expandRow = (row: AdminPromptType) => (
        <AdminPromptDetail isNew={false} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchAdminPrompt(searchOptions)
    }

    const columns = [
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
        }
    ]

    return (
        <Box m='20px'>
            <Header title='Admin Prompt' />

            <DataGridFilter
                keyField='id'
                useQueryKey={`admin-prompt`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={2}
                createPageUrl="/admin/prompt/create"
                expandRow={expandRow}
            />
        </Box>
    )
}

export default AdminPrompt