// User
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_ASSIGNEE = 'UPDATE_ASSIGNEE'
export const UNSET_UESR = 'UNSET_UESR'

// Table Data
export const LOAD_TABLE_DATA = 'LOAD_TABLE_DATA'
export const UPDATE_TABLE_DATA_ITEM = 'UPDATE_TABLE_DATA_ITEM'
export const DELETE_TABLE_DATA_ITEM = 'DELETE_TABLE_DATA_ITEM'

// Tabs
export const UPDATE_TAB_INDEX = 'UPDATE_TAB_INDEX'

// Chatbot
export const UPDATE_CHAT_SELECTED_OPTIONS = 'UPDATE_CHAT_SELECTED_OPTIONS'
export const ADD_CHAT_CONVERSATION = 'ADD_CHAT_CONVERSATION'
export const CLEAR_CHAT = 'CLEAR_CHAT'
export const CLEAR_ALL_CHAT = 'CLEAR_ALL_CHAT'