import {
    Box,
    Dialog,
    useTheme,
    Typography,
    TextField,
    DialogTitle,
    DialogActions,
    Slide,
} from '@mui/material'
import React, { useState } from 'react'
import { tokens } from '../theme'
import CloseIcon from '@mui/icons-material/Close'
import { CustomUploadFile } from '../interfaces/CustomUploadFileType'
import { Formik } from 'formik'
import * as yup from 'yup'
import DownloadIcon from '@mui/icons-material/Download'
import LinkIcon from '@mui/icons-material/Link'
import { TransitionProps } from '@mui/material/transitions'
import { useMutation } from 'react-query'
import { ApiError } from '../interfaces/ErrorType'
import { updateFile } from '../actions/content'
import ReactPlayer from 'react-player'
import { ROOT_CATEGORIES } from '../share/FileUploadConstant'
import ButtonExt from './ButtonExt'
import AutocompleteExt from './Autocomplete'

interface FileDetailsModalProps {
    userRole: string
    mediaType?: string
    file: CustomUploadFile | any
    selectedCategoryId: string
    mediaCategories: any
    fileList: CustomUploadFile[]
    setFileList: (fileList: CustomUploadFile[]) => void
    open: boolean
    downloadFile: (file: CustomUploadFile) => void
    deleteFile: (file: CustomUploadFile) => void
    onClose: (value: string) => void
}

const fileDetailSchema = yup.object().shape({
    name: yup.string().required('required'),
    categoryId: yup.string().optional(),
    altText: yup.string().optional(),
    caption: yup.string().optional(),
    tags: yup.array().optional(),
    metadata: yup.string().optional(),
})

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

const FileDetailsModal = ({
    userRole,
    mediaType,
    file,
    selectedCategoryId,
    mediaCategories,
    fileList,
    setFileList,
    open,
    onClose,
    downloadFile,
    deleteFile,
}: FileDetailsModalProps) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [deletePopupOpen, setDeletePopupOpen] = React.useState(false)
    const [linkCopied, setLinkCopied] = useState(false);

    const category = mediaCategories.find(
        (c: any) => c?.id === file?.fileStorageCategoryId
    )
    const isRootCategory = ROOT_CATEGORIES.includes(mediaCategories.find((mc: any) => mc.id === selectedCategoryId).name)

    const [initialValues, setInitialValues] = useState<any>({
        name: file?.name || '',
        categoryId: category?.id || '',
        altText: file?.altText || '',
        caption: file?.caption || '',
        tags: file?.tags || '',
        metadata: file.metadata || '',
    })

    const updateFileData = useMutation<any, ApiError, any>(updateFile)

    const onSaveClick = (values: any) => {
        const newFileList = fileList.map((f: CustomUploadFile) => {
            if (f.uid === file.uid) {
                return {
                    ...f,
                    name: values.name,
                    categoryId: values.categoryId,
                    altText: values.altText,
                    caption: values.caption,
                    tags: values.tags,
                    metadata: values.metadata,
                }
            }
            return f
        })
        setFileList(isRootCategory ? newFileList : newFileList.filter((f) => f.categoryId === category.id))

        const editableFile: any = newFileList.filter(
            (editFile) => editFile.uid === file.uid
        )[0]
        updateFileData.mutate(
            {
                data: {
                    fileStorageCategoryId: values.categoryId,
                    name: values.name,
                },
                key: editableFile?.key,
            },
            {
                onSuccess: (data) => {
                    onClose('')
                },
            }
        )
        onClose('')
    }

    const copyFileUrlToClipboard = () => {
        navigator.clipboard.writeText(file.thumbUrl)

        setLinkCopied(true);
        setTimeout(() => {
            setLinkCopied(false);
        }, 2000); // Reset after 2 seconds
    }

    const downloadCurrentFile = () => {
        downloadFile(file)
    }

    const deleteCurrentFile = () => {
        setDeletePopupOpen(true)
    }

    const handleDeletePopupClose = () => {
        setDeletePopupOpen(false)
    }

    const onConfirmDelete = () => {
        deleteFile(file)
    }

    return (
        <Dialog
            onClose={() => {
                onClose('')
            }}
            maxWidth="md"
            open={open}
            PaperProps={{
                sx: {
                    padding: 3,
                },
            }}
        >
            <Box
                sx={{
                    width: '800px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <Typography sx={{ fontSize: '1.2rem' }}>Details</Typography>

                    <CloseIcon onClick={() => onClose('')} />
                </Box>
                <Box sx={{ display: 'flex', gap: 5, overflowY: 'auto' }}>
                    <Box
                        sx={{
                            borderRadius: '8px',
                            backgroundColor: '#292828',
                            paddingY: '10px',
                            height: '130px',
                            position: 'relative',
                        }}
                    >
                        {(mediaType === 'MEDIA' || mediaType === 'BANNER') && (
                            <img
                                src={file?.thumbUrl}
                                alt={`${file?.altText || 'image'}`}
                                style={{
                                    width: '450px',
                                }}
                            />
                        )}

                        {mediaType === 'VIDEO' && (
                            <ReactPlayer
                                url={file?.thumbUrl}
                                controls
                                width="100%"
                                height="auto"
                                style={{ margin: 'auto' }}
                            />
                        )}

                        <Box sx={{ marginTop: '1em', display: 'flex', justifyContent: 'flex-end', gap: 2, overflowY: 'auto', textAlign: 'right' }}>
                            {/*<DeleteIcon onClick={deleteCurrentFile} />*/}
                            <DownloadIcon sx={{ cursor: `pointer` }} onClick={downloadCurrentFile} />
                            <LinkIcon sx={{ cursor: 'pointer' }} onClick={copyFileUrlToClipboard} />
                        </Box>
                        <Box sx={{ marginTop: '1em', display: 'flex', justifyContent: 'flex-end', overflowY: 'auto', textAlign: 'right' }}>
                            {linkCopied && <span style={{ marginLeft: '0.5em' }}>Copied</span>}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 5,
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                color: 'black',
                                backgroundColor: '#b7d9e8',
                                padding: '24px',
                                borderRadius: '8px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '50px',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography fontWeight="bold">
                                        Size
                                    </Typography>
                                    <Typography>
                                        {/* @ts-ignore */}
                                        {`${(file?.size / 1024)
                                            .toString()
                                            .slice(0, 7)}MB` || ''}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography fontWeight="bold">
                                        Extention
                                    </Typography>
                                    <Typography>{file?.type || ''}</Typography>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '50px',
                                }}
                            >
                                {file?.createdAt && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography fontWeight="bold">
                                            Created on
                                        </Typography>
                                        <Typography>
                                            {file?.createdAt?.split(' ')[0]}
                                        </Typography>
                                    </Box>
                                )}
                                {file?.updatedAt && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography fontWeight="bold">
                                            Updated on
                                        </Typography>
                                        <Typography>
                                            {file?.updatedAt?.split(' ')[0]}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '50px',
                                }}
                            >
                                {file?.createdBy &&
                                    Object.keys(file?.createdBy).length !==
                                        0 && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography fontWeight="bold">
                                                Created by
                                            </Typography>
                                            <Typography>
                                                {file?.createdBy?.displayName}
                                            </Typography>
                                            <Typography>
                                                {file?.createdBy?.username}
                                            </Typography>
                                        </Box>
                                    )}
                                {file?.updatedBy &&
                                    Object.keys(file?.updatedBy).length !==
                                        0 && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography fontWeight="bold">
                                                Updated by
                                            </Typography>
                                            <Typography>
                                                {file?.updatedBy?.displayName}
                                            </Typography>
                                            <Typography>
                                                {file?.updatedBy?.username}
                                            </Typography>
                                        </Box>
                                    )}
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '50px',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography fontWeight="bold">
                                        Asset ID
                                    </Typography>
                                    <Typography>{file?.uid}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Formik
                            onSubmit={onSaveClick}
                            initialValues={initialValues}
                            validationSchema={fileDetailSchema}
                        >
                            {({ values, handleChange, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 2,
                                        }}
                                    >
                                        <>
                                            <TextField
                                                style={{ marginLeft: '1em' }}
                                                fullWidth={true}
                                                variant="standard"
                                                type="text"
                                                label="File Name"
                                                onChange={(e) => {
                                                    setInitialValues({
                                                        ...initialValues,
                                                        name: e.target.value,
                                                    })
                                                    values.name = e.target.value
                                                }}
                                                value={values.name}
                                                name="name"
                                            />

                                            <AutocompleteExt
                                                name="category-label"
                                                multiSelection={false}
                                                label="Category"
                                                selectedValue={values.categoryId || ''}
                                                onSelect={(value) => {
                                                    setInitialValues({
                                                        ...initialValues,
                                                        categoryId: value,
                                                    })
                                                    values.categoryId = value
                                                }}
                                                options={mediaCategories.map((cat: any) => {
                                                    return {
                                                        value: cat.id,
                                                        label: cat.name,
                                                    }
                                                })}
                                                disableUnselectAll={true}
                                            />

                                            {/*<TextField*/}
                                            {/*    fullWidth={true}*/}
                                            {/*    variant="standard"*/}
                                            {/*    type="text"*/}
                                            {/*    label="Alternative Text"*/}
                                            {/*    onChange={handleChange}*/}
                                            {/*    value={values.altText}*/}
                                            {/*    name="altText"*/}
                                            {/*/>*/}

                                            {/*<TextField*/}
                                            {/*    fullWidth={true}*/}
                                            {/*    variant="standard"*/}
                                            {/*    type="text"*/}
                                            {/*    label="Caption"*/}
                                            {/*    onChange={handleChange}*/}
                                            {/*    value={values.caption}*/}
                                            {/*    name="caption"*/}
                                            {/*/>*/}

                                            {/* <Box
                                                display={'flex'}
                                                flexDirection={'column'}
                                                gap={2}
                                            >
                                                <label>Tags</label>
                                                <InputTag
                                                    fileTags={fileTags}
                                                    setFileTags={setFileTags}
                                                />
                                            </Box> */}
                                            {/*<TextField*/}
                                            {/*    fullWidth={true}*/}
                                            {/*    variant="standard"*/}
                                            {/*    type="text"*/}
                                            {/*    label="Meta data"*/}
                                            {/*    onChange={handleChange}*/}
                                            {/*    value={values.metadata}*/}
                                            {/*    name="metadata"*/}
                                            {/*/>*/}
                                        </>
                                    </Box>
                                    <Box
                                        sx={{
                                            marginTop: '30px',
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <ButtonExt
                                            type="button"
                                            value="Cancel"
                                            onClickEvent={() => {
                                                onClose('')
                                            }}
                                        />
                                        <ButtonExt
                                            type="submit"
                                            value="Save"
                                        />
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Box>
            <Dialog
                style={{ zIndex: '10000' }}
                open={deletePopupOpen}
                PaperProps={{
                    style: { width: '250px', height: '150px' }, // Adjust width and height as needed
                }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDeletePopupClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{ fontSize: '24px' }}>
                    {'Are you sure ?'}
                </DialogTitle>
                <DialogActions>
                    <ButtonExt
                        type="button"
                        value="Delete"
                        onClickEvent={onConfirmDelete}
                    />
                    <ButtonExt
                        type="button"
                        value="Cancel"
                        onClickEvent={handleDeletePopupClose}
                    />
                </DialogActions>
            </Dialog>
        </Dialog>
    )
}

export default FileDetailsModal
