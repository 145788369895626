import { Box } from '@mui/material'
import Header from '../../components/Header'

const Dashboard = () => {
    return (
        <Box m="20px">
            <Box justifyContent="space-between" alignItems="center">
                <Header title="DASHBOARD" subtitle="Guideline" />

                <div style={{ whiteSpace: `pre-wrap`, lineBreak: `anywhere` }}>
                    <p>
                        Hello and welcome to Hana Console, where you can get
                        expert answers to your queries! Here's a step-by-step
                        guide on how to use our system:
                    </p>
                    <br />

                    <ol>
                        <li>
                            First, navigate to the prompt query menu located on
                            the left-hand side of the sidebar.
                        </li>
                        <li>
                            Choose the prompt option that best fits your area of
                            interest or expertise. Our prompt options are based
                            on domain experts assigned by the system
                            administrator.
                        </li>
                        <li>
                            You can now ask any query related to your subject
                            expert. Our advanced algorithms will analyze your
                            question and provide you with accurate and relevant
                            answers.
                        </li>
                        <li>
                            In case you can't find a prompt option related to
                            your area of interest, feel free to use our general
                            prompt option. This allows you to ask any general
                            question you may have.
                        </li>
                        <li>
                            To ensure accurate answers, please follow the
                            guidelines provided by Hana Console when asking
                            questions. These guidelines will help you ask clear
                            and concise questions.
                        </li>
                        <li>
                            Please provide feedback to the administrator. Your
                            feedback will help us to improve the system and make
                            it more user-friendly.
                        </li>
                    </ol>

                    <br />
                    <p>
                        We hope this guideline will help you with ease. If you
                        have any further questions or concerns, please do not
                        hesitate to contact us.
                    </p>
                    <br />
                    <p>Thank you for using Hana!</p>
                </div>
            </Box>
        </Box>
    )
}

export default Dashboard
