/**
 * An action to load available prompt types
 */
import SecureAxios from '../extensions/SecureAxios'

export async function loadAvailablePromptTypes() {
    const { data } = await SecureAxios.get(`/hana/prompts`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data.data
}

export async function loadAvailableUserTypes() {
    const { data } = await SecureAxios.get(`/hana/admin/user/types`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data.data
}

export const createUserType = async (type) => {
    const { data } = await SecureAxios.post(`/hana/admin/user/type`, {type: type}, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data.data
}

/**
 * An action to fetch admin prompt
 * @param searchOptions
 * @returns {Promise<*>}
 */
export const fetchAdminPrompt = async (searchOptions) => {
    let promptPermissionsUrl = `/hana/admin/prompts?keyword=${searchOptions.keyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        promptPermissionsUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    const { data } = await SecureAxios.get(promptPermissionsUrl)
    return data
}

/**
 * An action to save prompt
 */
export async function savePrompt(prompt) {
    const requestBody = {
        ...prompt,
    }
    let promptUrl = `/hana/admin/prompt`
    if (prompt.id) {
        promptUrl += `/${prompt.id}/update`

        const { data } = await SecureAxios.put(promptUrl, requestBody, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return data.data
    } else {
        const { data } = await SecureAxios.post(promptUrl, requestBody, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return data.data
    }
}

/**
 * An action to prompt a query
 */
export async function promptQuery(promptQueryRequest) {
    const requestBody = {
        ...promptQueryRequest,
    }
    const { data } = await SecureAxios.post(`/hana/query/async`, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    const eventId = data.eventId

    const update = await promptQueryStatusUpdate(eventId);
    return update.data;
}

/**
 * An action to fetch event update
 * @param searchOptions
 * @returns {Promise<*>}
 */
export const promptQueryStatusUpdate = async (eventId) => {
    const { data } = await SecureAxios.get(`/hana/query/async/${eventId}/status`)
    return data
}

/**
 * An action to clear prompt query history state
 */
export async function clearPromptQueryHistoryState(promptQueryHistoryRemoveRequest) {
    const requestBody = {
        ...promptQueryHistoryRemoveRequest,
    }
    await SecureAxios.post(`/hana/query/history/remove`, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
}
