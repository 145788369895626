import SecureAxios from '../extensions/SecureAxios'
import store from '../redux/store'
import { UPDATE_ASSIGNEE, UPDATE_TABLE_DATA_ITEM } from '../redux/actionType'

export const fetchAssignees = async (searchOptions) => {
    let assigneesUrl = `/hana/admin/user/assignees?keyword=${searchOptions.keyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        assigneesUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    const { data } = await SecureAxios.get(assigneesUrl)

    return data
}

export const findMyAssignee = async () => {
    const { data } = await SecureAxios.get(`/hana/assignee`)

    store.dispatch({
        type: UPDATE_ASSIGNEE,
        payload: data.data[0],
    })

    return data
}

export async function findUsers() {
    const { data } = await SecureAxios.get(`/hana/admin/username`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data.data
}

export async function findContentAssignees() {
    const { data } = await SecureAxios.get(`/hana/admin/content/assignees`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data.data
}

export async function findMedicalAssignees() {
    const { data } = await SecureAxios.get(`/hana/admin/medical/assignees`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data.data
}

export async function updateAssignee(assignee) {
    const requestBody = {
        ...assignee,
    }

    const { data } = await SecureAxios.put(`/hana/admin/user/assignee/${assignee.id}/update`, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: { key: 'admin-assignees', keyField: 'id', data: data.data },
    })

    return data.data
}