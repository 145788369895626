import React, { useEffect, useRef, useState } from 'react'
import { Paper } from '@material-ui/core'
import { MessageLeft, MessageRight } from './Message'
import { Backdrop, Box, CircularProgress, InputAdornment, TextField, useTheme } from '@mui/material'
import { tokens } from '../../../../theme'
import { connect } from 'react-redux'
import InputSend from './InputSend'
import { useMutation } from 'react-query'
import { ApiError } from '../../../../interfaces/ErrorType'
import ErrorMessage from '../../../../components/ErrorMessage'
import {
    PromptQueryRequest,
    PromptQueryResponse,
} from '../../../../interfaces/PromptType'
import { promptQuery } from '../../../../actions/prompt'
import { AuthToken } from '../../../../actions/auth'
import store from '../../../../redux/store'
import { ADD_CHAT_CONVERSATION } from '../../../../redux/actionType'
import SearchIcon from '@mui/icons-material/Search'

const Chatbot = (props: {
    user: AuthToken
    chatbotConversationHistory: Array<{
        query: {
           isBot: boolean,
           message: string,
           referenceId: string 
        },
        response: {
            isBot: boolean,
            message: string,
            referenceId: string 
        }
    }>,
    promptId?: string | undefined
    service: string
    remember: boolean
}) => {
    const { user, promptId, service, remember, chatbotConversationHistory } = props
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    // const [messages, setMessages] = useState<Message[]>([])
    const chatbotConversationHistoriesEndRef = useRef<HTMLDivElement>(null)
    const [keyword, setKeyword] = useState('')
    const [chatbotConversationHistories, setChatbotConversationHistories] = useState(chatbotConversationHistory)

    useEffect(() => {
        setChatbotConversationHistories(chatbotConversationHistory)
    }, [chatbotConversationHistory])

    /**
     * Mutate prompt query
     */
    const promptQueryMutation = useMutation<
        PromptQueryResponse,
        ApiError,
        PromptQueryRequest
    >(promptQuery)

    useEffect(() => {
        scrollToBottom()
    }, [chatbotConversationHistories])

    const handleMessage = (v: string) => {
        setKeyword('')
        promptQueryMutation.mutate(
            { promptId: promptId!!, query: v, service: service, remember: remember },
            {
                onSuccess: async (data) => {
                    store.dispatch({
                        type: ADD_CHAT_CONVERSATION,
                        payload: {
                            query: {message: v, referenceId: '', isBot: false },
                            response: { message: data.result, referenceId: data.referenceId, isBot: true }
                        }
                    })
                },
            }
        )
    }

    const scrollToBottom = () => {
        chatbotConversationHistoriesEndRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
        })
    }

    return (
        <>
            {promptQueryMutation.isError && (
                <ErrorMessage error={promptQueryMutation.error} />
            )}

            <Box style={{width: `100%`} }>
                <TextField
                    placeholder="Search"
                    id="outlined-start-adornment"
                    fullWidth
                    value={keyword}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        const keyword = e.target.value
                        setKeyword(keyword)
                        if (keyword) {
                            const keywordInLowerCase = keyword.toLowerCase()
                            setChatbotConversationHistories(
                                chatbotConversationHistory.filter((message) => {
                                    return message.query.message.toLowerCase().includes(keywordInLowerCase) || message.response.message.toLowerCase().includes(keywordInLowerCase)
                                })
                            )
                        } else {
                            setChatbotConversationHistories(chatbotConversationHistory)
                        }
                    }}
                />
            </Box>

            <Box
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor:
                            theme.palette.mode === 'dark'
                                ? colors.grey[100]
                                : colors.primary[900],
                    },

                    '& .MuiPaper-MessageBody': {
                        width: 'calc( 100% - 20px )',
                        margin: 1,
                        overflowY: 'scroll',
                        height: '50vh',
                    },

                    '& .MuiBox-Container': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    },

                    '& .MuiBox-Panel': {
                        width: 'auto',
                        height: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        position: 'relative',
                    },
                }}
                className="MuiBox-Container"
            >
                <Paper className="MuiBox-Panel">
                    <Paper className="MuiPaper-MessageBody">
                        {chatbotConversationHistories.map((message) => {
                            return (
                                <>
                                    <MessageRight
                                        message={message.query.message}
                                        displayName={user?.user?.username ? user.user.username : 'User'}
                                    />
                                    <MessageLeft
                                        referenceId={message.response.referenceId}
                                        message={message.response.message}
                                        displayName="Bot"
                                    />
                                </>
                                )
                        })}

                        <Backdrop
                            sx={{
                                color: '#fff',
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={promptQueryMutation.isLoading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>

                        <div ref={chatbotConversationHistoriesEndRef} />
                    </Paper>
                    <InputSend
                        promptId={promptId}
                        onMessage={handleMessage}
                        disabled={promptQueryMutation.isLoading || !promptId}
                        maxLength={
                            promptId === 'c7c23611-4324-4c52-a385-d8011f2403c4'
                                ? 3000
                                : 1500
                        }
                    />
                </Paper>
            </Box>
        </>
    )
}

interface Message {
    referenceId: string
    message: string
    isBot: boolean
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user, chatbotConversationHistory: state.chatbotConversation.data }
}

export default connect(mapStateToProps)(Chatbot)
