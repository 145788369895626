import React  from 'react';
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

interface AlertDialogProps {
    open: boolean
    message: string
    onAcknowledge: () => void;
}

const AlertDialog: React.FC<AlertDialogProps> = ({ open, message, onAcknowledge }) => {
    const handleAcknowledge = () => {
        onAcknowledge();
    };

    return (
        <>
            {open && (
                <Dialog open={open} onClose={handleAcknowledge}>
                    <DialogContent>
                        <Alert severity="info">
                            <AlertTitle>Alert</AlertTitle>
                            <DialogContentText>{message}</DialogContentText>
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAcknowledge}>Ok</Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default AlertDialog;