import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import Header from '../../../components/Header'
import Chatbot from './bot/Chatbot'
import AutocompleteExt from '../../../components/Autocomplete'
import { useAuthQueryWithQueryFunction } from '../../../extensions/UseAuthQuery'
import { ApiError } from '../../../interfaces/ErrorType'
import {
    PromptQueryHistoryRemoveRequest,
    PromptType,
} from '../../../interfaces/PromptType'
import { clearPromptQueryHistoryState, loadAvailablePromptTypes, promptQuery } from '../../../actions/prompt'
import CheckboxExt from '../../../components/Checkbox'
import { useMutation } from 'react-query'
import ButtonExt from '../../../components/ButtonExt'
import ErrorMessage from '../../../components/ErrorMessage'
import { chatbotServiceOptions } from '../../../share/ChatbotConstant'
import { connect } from 'react-redux'
import store from '../../../redux/store'
import { CLEAR_CHAT, UPDATE_CHAT_SELECTED_OPTIONS } from '../../../redux/actionType'

function PromptQueryAssistant(props: {selectedOptions: any}) {
    const { selectedOptions } = props
    const [selectedPromptId, setSelectedPromptId] = useState<string>()
    const [selectedService, setSelectedService] = useState<string>('DEFAULT')
    const [remember, setRemember] = useState<boolean>(true)
    const [promptQueryHistoryRemoveRequest, setPromptQueryHistoryRemoveRequest] = useState<PromptQueryHistoryRemoveRequest>({
        promptId: selectedPromptId
    })

    useEffect(() => {
        if (selectedOptions?.selectedPromptId) {
            setSelectedPromptId(selectedOptions?.selectedPromptId)
            setPromptQueryHistoryRemoveRequest({
                promptId: selectedOptions?.selectedPromptId
            })
        }
    }, [selectedOptions?.selectedPromptId])

    useEffect(() => {
        if (selectedOptions?.selectedService) {
            setSelectedService(selectedOptions?.selectedService)
        }
    }, [selectedOptions?.selectedService])

    useEffect(() => {
        if (selectedOptions?.rememberConversation) {
            setRemember(selectedOptions?.rememberConversation)
        }
    }, [selectedOptions?.rememberConversation])

    /**
     * Available prompt type query
     */
    const availablePromptTypeQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        PromptType[]
    >('promptType', loadAvailablePromptTypes, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Mutate clear prompt query history state
     */
    const clearPromptQueryHistoryStateMutation = useMutation<
        any,
        ApiError,
        PromptQueryHistoryRemoveRequest
        >(clearPromptQueryHistoryState)

    const availablePromptTypeOptions = availablePromptTypeQuery.data?.map(
        (promptType) => {
            return {
                value: promptType.id,
                label: promptType.title,
            }
        }
    )

    const onClearPromptQueryHistory = async () => {
        clearPromptQueryHistoryStateMutation.mutate(promptQueryHistoryRemoveRequest, {
            onSuccess: () => {
                store.dispatch({
                    type: CLEAR_CHAT
                });
                alert('Your chat history has been successfully cleared based on your selected prompt.')
            }
        })
    }

    return (
        <Box m="10px">
            <Header title="Query Assistant" />

            <Box style={{ marginBottom: `2em` }}>
                {clearPromptQueryHistoryStateMutation.isError && (
                    <ErrorMessage error={clearPromptQueryHistoryStateMutation.error} />
                )}
            </Box>

            <Box display="grid" gap="5px" gridTemplateColumns="7fr 4fr 2fr 1fr" style={{ marginBottom: `10px` }}>
                <AutocompleteExt
                    name="selectedPromptId"
                    multiSelection={false}
                    label="Select a prompt..."
                    selectedValue={selectedPromptId}
                    options={availablePromptTypeOptions}
                    onSelect={(v) => {
                        setSelectedPromptId(v)
                        setPromptQueryHistoryRemoveRequest({
                            promptId: v
                        })
                        // Update selected options
                        store.dispatch({
                            type: UPDATE_CHAT_SELECTED_OPTIONS,
                            payload: {
                                selectedPromptId: v,
                                selectedService: selectedService,
                                rememberConversation: remember
                            }
                        })
                    }}
                    required={true}
                    disableUnselectAll={true}
                />
                <AutocompleteExt
                    name="selectedService"
                    multiSelection={false}
                    label="Select a service..."
                    selectedValue={selectedService}
                    options={chatbotServiceOptions}
                    onSelect={(v) => {
                        setSelectedService(v)
                        // Update selected options
                        store.dispatch({
                            type: UPDATE_CHAT_SELECTED_OPTIONS,
                            payload: {
                                selectedPromptId: selectedPromptId,
                                selectedService: v,
                                rememberConversation: remember
                            }
                        })
                    }}
                    required={true}
                    disableUnselectAll={true}
                />
                <CheckboxExt
                    name="remember"
                    label="Remember Conversation"
                    value={remember}
                    onChange={(v) => {
                        setRemember(v);
                        // Update selected options
                        store.dispatch({
                            type: UPDATE_CHAT_SELECTED_OPTIONS,
                            payload: {
                                selectedPromptId: selectedPromptId,
                                selectedService: selectedService,
                                rememberConversation: v
                            }
                        })
                    }}
                />
                <ButtonExt
                    type="button"
                    onClickEvent={onClearPromptQueryHistory}
                    value="Clear History"
                    disabled={!promptQueryHistoryRemoveRequest.promptId}
                />
            </Box>

            <Chatbot promptId={selectedPromptId} service={selectedService} remember={remember} />
        </Box>
    )
}

/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { selectedOptions: state.chatbotConversation.selectedOptions }
}

export default connect(mapStateToProps)(PromptQueryAssistant)