import React, { FunctionComponent, useState } from 'react'
import { Box, TextField } from '@mui/material'
import {
    PromptPermission,
    PromptPermissionRequest,
    PromptPermissionWrapper,
} from '../../../../../interfaces/PromptPermissionType'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useMutation } from 'react-query'
import { ApiError } from '../../../../../interfaces/ErrorType'
import { updatePromptPermission } from '../../../../../actions/promptPermission'
import AutocompleteExt from '../../../../../components/Autocomplete'
import { useAuthQueryWithQueryFunction } from '../../../../../extensions/UseAuthQuery'
import { UserType } from '../../../../../interfaces/PromptType'
import { loadAvailableUserTypes } from '../../../../../actions/prompt'
import ButtonExt from '../../../../../components/ButtonExt'

const promptPermissionSchema = yup.object().shape({
    userTypeIds: yup.array().min(1).required('required'),
})

const AdminPromptPermissionDetail: FunctionComponent<PromptPermissionWrapper> = ({
                                                                                     wrapper,
                                                                                 }) => {
    const [promptPermission, setPromptPermission] = useState<PromptPermission>(wrapper!!)
    const [promptPermissionRequest, setPromptPermissionRequest] = useState<PromptPermissionRequest>({
        id: wrapper!!.id,
        username: wrapper!!.username,
        userTypeIds: wrapper!!.userTypes?.map(userType => userType.id),
    })

    /**
     * Available user type query
     */
    const availableUserTypeQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        UserType[]
        >('userType', loadAvailableUserTypes, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const availableUserTypeOptions = availableUserTypeQuery.data?.map(
        (userType) => {
            return {
                value: userType.id,
                label: userType.type,
            }
        }
    )

    /**
     * Invoke an action to update prompt permission
     * @param {*} e - event
     */
    const onSave = (values: PromptPermissionRequest) => {
        promptPermissionUpdateMutation.mutate(values, {
            onSuccess: (data) => {
                setPromptPermission(data)
                setPromptPermissionRequest(data)
                values = data
            },
        })
    }

    /**
     * Mutate prompt permission update
     */
    const promptPermissionUpdateMutation = useMutation<PromptPermission, ApiError, PromptPermissionRequest>(
        updatePromptPermission,
        {
            onSuccess: (data) => {
                setPromptPermission(data)
            },
        },
    )

    /**
     * Page containing prompt permission detail page
     */
    return (
        <Box m='20px'>
            <Formik
                onSubmit={onSave}
                initialValues={promptPermissionRequest}
                validationSchema={promptPermissionSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display='grid'
                            gap='30px'
                            gridTemplateColumns='repeat(1, minmax(0,1fr))'
                        >
                            <TextField
                                variant='filled'
                                type='text'
                                label='Id'
                                value={values.id}
                                name='id'
                            />

                            <AutocompleteExt
                                name="userTypeIds"
                                multiSelection={true}
                                label="User Types"
                                selectedValue={values.userTypeIds}
                                options={availableUserTypeOptions}
                                onSelect={(v) => {
                                    setPromptPermissionRequest({
                                        ...promptPermission,
                                        userTypeIds: v
                                    })
                                    values.userTypeIds = v
                                }}
                                error={!!touched.userTypeIds && !!errors.userTypeIds}
                                helperText={touched.userTypeIds && errors.userTypeIds}
                            />
                        </Box>
                        <Box
                            display='flex'
                            justifyContent='end'
                            mt='20px'
                            gap='20px'
                        >
                            <ButtonExt
                                type='submit'
                                value={
                                    promptPermissionUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    promptPermissionUpdateMutation.isLoading ||
                                    values.userTypeIds?.length === 0
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default AdminPromptPermissionDetail