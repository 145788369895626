import { Box, Container, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import InfoIcon from '@mui/icons-material/Info'
import DeleteIcon from '@mui/icons-material/Delete';
import { tokens } from '../theme'
import { ContentSubmissionRequest } from '../interfaces/ContentType'
import { useAuthQueryWithQueryFunction } from '../extensions/UseAuthQuery'
import { ApiError } from '../interfaces/ErrorType'
import ErrorMessage from './ErrorMessage'
import ReactPlayer from 'react-player'
import AddMediaModal from './AddMediaModal'
import ExpandableImage from './ExpandableImage'
import { getFile } from '../actions/content'

interface AddMediaProps {
    contentSubmissionRequestId: string
    contentSubmissionRequest: ContentSubmissionRequest
    onChangeEvent: (mediaType: string, key: string, promptUsed?: string) => void
    onClearMedia: (mediaType: string) => void
}

const AddMedia = ({ contentSubmissionRequestId, contentSubmissionRequest, onChangeEvent, onClearMedia }: AddMediaProps) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [open, setOpen] = useState(false)
    const [mediaType, setMediaType] = useState<string>('')

    // Fetch files to render based on keys
    const [mediaFile, setMediaFile] = useState<any>('')
    const [bannerFile, setBannerFile] = useState<any>('')
    const [videoFile, setVideoFile] = useState<any>('')

    const handleMediaDialogOpen = (mediaType: string) => {
        setMediaType(mediaType)
        setOpen(true)
    }

    const handleMediaDialogClose = () => {
        setOpen(false)
    }

    const handleMediaAssignmentFile = async () => {
        return await getFile(contentSubmissionRequest.mediaKey);
    }

    const handleBannerAssignmentFile = async () => {
        return await getFile(contentSubmissionRequest.bannerKey);
    }

    const handleVideoAssignmentFile = async () => {
        return await getFile(contentSubmissionRequest.videoKey);
    }

    const mediaQuery = useAuthQueryWithQueryFunction<undefined, ApiError, any>(
        `media-key-${contentSubmissionRequest.mediaKey}`,
        handleMediaAssignmentFile,
        {
            refetchOnWindowFocus: false,
            enabled: contentSubmissionRequest.mediaKey ? true : false,
        }
    )

    const videoQuery = useAuthQueryWithQueryFunction<undefined, ApiError, any>(
        `video-key-${contentSubmissionRequest.videoKey}`,
        handleVideoAssignmentFile,
        {
            refetchOnWindowFocus: false,
            enabled: contentSubmissionRequest.videoKey ? true : false,
        }
    )

    useEffect(() => {
        if (mediaQuery.data) {
            setMediaFile(mediaQuery.data)
        }
    }, [mediaQuery.data])

    const bannerQuery = useAuthQueryWithQueryFunction<undefined, ApiError, any>(
        `banner-key-${contentSubmissionRequest.bannerKey}`,
        handleBannerAssignmentFile,
        {
            refetchOnWindowFocus: false,
            enabled: contentSubmissionRequest.bannerKey ? true : false,
        }
    )

    useEffect(() => {
        if (bannerQuery.data) {
            setBannerFile(bannerQuery.data)
        }
    }, [bannerQuery.data])

    useEffect(() => {
        if (videoQuery.data) {
            setVideoFile(videoQuery.data)
        }
    }, [videoQuery.data])

    return (
        <Box sx={{ display: 'flex', gap: 5 }}>
            <Box width={'100%'}>
                <Typography paddingY={1}>
                    Media
                    <Tooltip style={{position: 'absolute', left: 50, zIndex: 15}} title="Prior to submitting content for approval, it is essential to include media." placement="top">
                        <InfoIcon />
                    </Tooltip>
                </Typography>
                <Box
                    display={'flex'}
                    flexDirection={`${contentSubmissionRequest.mediaKey ? 'row' : 'column'}`}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                        border: '1px solid grey',
                        height: 130,
                        gap: '15px',
                        padding: '10px',
                    }}
                >
                    {mediaQuery.isError ? (
                        <ErrorMessage
                            error={mediaQuery.error}
                        />
                    ) : 
                    contentSubmissionRequest.mediaKey && mediaFile?.url && (
                        <ExpandableImage
                            style={{ objectFit: 'contain', width: 200 }}
                            url={mediaFile?.url}
                            name={mediaFile?.name}
                            className='me-3'
                        />
                    )}

                    {!contentSubmissionRequest.mediaKey &&
                        <div style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleMediaDialogOpen('MEDIA')}>
                            <AddPhotoAlternateIcon
                                sx={{ color: colors.greenAccent[500] }}
                                fontSize="small"
                            />
                            <Typography
                                marginTop={1}
                                color={colors.grey[200]}
                                fontSize={'12px'}
                            >
                                Click to add an asset or generate an image in this area
                            </Typography>
                        </div>
                    }

                    {contentSubmissionRequest.mediaKey &&
                        <div style={{ textAlign: 'left', cursor: 'pointer' }} onClick={() => handleMediaDialogOpen('MEDIA')}>
                            <Typography
                                marginTop={1}
                                color={colors.grey[200]}
                                fontSize={'12px'}
                            >
                                Click to edit an asset or generate an image in this area
                            </Typography>
                        </div>
                    }

                    {contentSubmissionRequest.mediaKey && (
                        <Box sx={{ marginTop: '1em', display: 'flex', justifyContent: 'flex-end', overflowY: 'auto', textAlign: 'right' }}>
                            <DeleteIcon
                                sx={{
                                    position: 'absolute',
                                    cursor: 'pointer',
                                    borderRadius: '50%', // Rounded shape for the button
                                    marginTop: '-60px', // Position the button
                                    marginRight: '-5px', // Position the button
                                    zIndex: 10, // Ensure the icon is above other content
                                }}
                                onClick={() => onClearMedia('MEDIA')}
                            />
                        </Box>
                    )}
                </Box>
            </Box>

            <Box width={'100%'}>
                <Typography paddingY={1}>Banner</Typography>
                <Box
                    display={'flex'}
                    flexDirection={`${contentSubmissionRequest.bannerKey ? 'row' : 'column'}`}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                        border: '1px solid grey',
                        height: 130,
                        gap: '15px',
                        padding: '10px',
                    }}
                >
                    {bannerQuery.isError ? (
                        <ErrorMessage
                            error={bannerQuery.error}
                        />
                    ): contentSubmissionRequest.bannerKey && bannerFile?.url && (
                        <ExpandableImage
                            style={{ objectFit: 'contain', width: 200 }}
                            url={bannerFile?.url}
                            name={bannerFile?.name}
                            className='me-3'
                        />
                    )}

                    {!contentSubmissionRequest.bannerKey &&
                        <div style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleMediaDialogOpen('BANNER')}>
                            <AddPhotoAlternateIcon
                                sx={{ color: colors.greenAccent[500] }}
                                fontSize="small"
                            />
                            <Typography
                                marginTop={1}
                                color={colors.grey[200]}
                                fontSize={'12px'}
                            >
                                Click to add an asset or generate an image in this area
                            </Typography>
                        </div>
                    }

                    {contentSubmissionRequest.bannerKey &&
                        <div style={{ textAlign: 'left', cursor: 'pointer' }} onClick={() => handleMediaDialogOpen('BANNER')}>
                            <Typography
                                marginTop={1}
                                color={colors.grey[200]}
                                fontSize={'12px'}
                            >
                                Click to edit an asset or generate an image in this area
                            </Typography>
                        </div>
                    }

                    {contentSubmissionRequest.bannerKey && (
                        <Box sx={{ marginTop: '1em', display: 'flex', justifyContent: 'flex-end', overflowY: 'auto', textAlign: 'right' }}>
                            <DeleteIcon
                                sx={{
                                    position: 'absolute',
                                    cursor: 'pointer',
                                    borderRadius: '50%', // Rounded shape for the button
                                    marginTop: '-60px', // Position the button
                                    marginRight: '-5px', // Position the button
                                    zIndex: 10, // Ensure the icon is above other content
                                }}
                                onClick={() => onClearMedia('BANNER')}
                            />
                        </Box>
                    )}
                </Box>
            </Box>

            <Box width={'100%'}>
                <Typography paddingY={1}>Video</Typography>
                <Box
                    display={'flex'}
                    flexDirection={`${contentSubmissionRequest.videoKey ? 'row' : 'column'}`}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                        border: '1px solid grey',
                        height: 130,
                        gap: '15px',
                        padding: '10px',
                    }}
                >
                    {videoQuery.isError ? (
                        <ErrorMessage
                            error={videoQuery.error}
                        />
                    ): contentSubmissionRequest.videoKey && videoFile?.url && (
                        <ReactPlayer
                            url={videoFile?.url}
                            controls
                            width="100%"
                            height="auto"
                            style={{ margin: 'auto' }}
                        />
                    )}

                    {!contentSubmissionRequest.videoKey &&
                        <div style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleMediaDialogOpen('VIDEO')}>
                            <AddPhotoAlternateIcon
                                sx={{ color: colors.greenAccent[500] }}
                                fontSize="small"
                            />
                            <Typography
                                marginTop={1}
                                color={colors.grey[200]}
                                fontSize={'12px'}
                            >
                                Click to add an asset or generate a video in this area
                            </Typography>
                        </div>
                    }

                    {contentSubmissionRequest.videoKey &&
                        <div style={{ textAlign: 'left', cursor: 'pointer' }} onClick={() => handleMediaDialogOpen('VIDEO')}>
                            <Typography
                                marginTop={1}
                                color={colors.grey[200]}
                                fontSize={'12px'}
                            >
                                Click to edit an asset or generate a video in this area
                            </Typography>
                        </div>
                    }

                    {contentSubmissionRequest.videoKey && (
                        <Box sx={{ marginTop: '1em', display: 'flex', justifyContent: 'flex-end', overflowY: 'auto', textAlign: 'right' }}>
                            <DeleteIcon
                                sx={{
                                    position: 'absolute',
                                    cursor: 'pointer',
                                    borderRadius: '50%', // Rounded shape for the button
                                    marginTop: '-60px', // Position the button
                                    marginRight: '-5px', // Position the button
                                    zIndex: 10, // Ensure the icon is above other content
                                }}
                                onClick={() => onClearMedia('VIDEO')}
                            />
                        </Box>
                    )}
                </Box>
            </Box>

            <AddMediaModal
                open={open}
                onClose={handleMediaDialogClose}
                contentSubmissionRequestId={contentSubmissionRequestId}
                mediaType={mediaType}
                contentSubmissionRequest={contentSubmissionRequest}
                onChangeEvent={onChangeEvent}
            />
        </Box>
    )
}

export default AddMedia
