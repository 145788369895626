import React, { FunctionComponent, useState } from 'react'
import { Box, TextField } from '@mui/material'
import { HanaAssignee, HanaAssigneeRequest, HanaAssigneeWrapper } from '../../../../interfaces/AssigneeType'
import { ApiError } from '../../../../interfaces/ErrorType'
import { updateAssignee } from '../../../../actions/user'
import { useMutation } from 'react-query'
import { Formik } from 'formik'
import * as yup from 'yup'
import AutocompleteExt from '../../../../components/Autocomplete'
import ButtonExt from '../../../../components/ButtonExt'

const promptPermissionSchema = yup.object().shape({
    displayName: yup.string().required('required'),
    assigneeTypes: yup.array().min(1).required('required'),
})

const AdminAssigneeDetail: FunctionComponent<HanaAssigneeWrapper> = ({
                                                                                     wrapper,
                                                                                 }) => {
    const [hanaAssignee, setHanaAssignee] = useState<HanaAssignee>(wrapper!!)
    const [hanaAssigneeRequest, setHanaAssigneeRequest] = useState<HanaAssigneeRequest>({
        ...hanaAssignee
    })

    /**
     * Invoke an action to update assignee
     * @param {*} e - event
     */
    const onSave = (values: HanaAssigneeRequest) => {
        promptPermissionUpdateMutation.mutate(values, {
            onSuccess: (data) => {
                setHanaAssignee(data)
                setHanaAssigneeRequest(data)
                values = data
            },
        })
    }

    /**
     * Mutate assignee update
     */
    const promptPermissionUpdateMutation = useMutation<HanaAssignee, ApiError, HanaAssigneeRequest>(
        updateAssignee,
        {
            onSuccess: (data) => {
                setHanaAssignee(data)
            },
        },
    )

    const availableAssigneeTypeOptions = [
        {
            value: 'CONTENT',
            label: 'Content',
        },
        {
            value: 'MEDICAL',
            label: 'Medical',
        },
    ]

    /**
     * Page containing prompt permission detail page
     */
    return (
        <Box m='20px'>
            <Formik
                onSubmit={onSave}
                initialValues={hanaAssigneeRequest}
                validationSchema={promptPermissionSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display='grid'
                            gap='30px'
                            gridTemplateColumns='repeat(1, minmax(0,1fr))'
                        >
                            <TextField
                                variant='filled'
                                type='text'
                                label='Id'
                                value={values.id}
                                name='id'
                            />

                            <TextField
                                variant='filled'
                                type='text'
                                label='Username'
                                value={hanaAssignee.username}
                                name='username'
                            />

                            <TextField
                                variant='filled'
                                type='text'
                                label='Display Name'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.displayName}
                                name='displayName'
                                error={!!touched.displayName && !!errors.displayName}
                                helperText={touched.displayName && errors.displayName}
                            />

                            <AutocompleteExt
                                name="assigneeTypes"
                                multiSelection={true}
                                label="Types"
                                selectedValue={values.assigneeTypes}
                                options={availableAssigneeTypeOptions}
                                onSelect={(v) => {
                                    setHanaAssigneeRequest({
                                        ...hanaAssignee,
                                        assigneeTypes: v
                                    })
                                    values.assigneeTypes = v
                                }}
                                error={!!touched.assigneeTypes && !!errors.assigneeTypes}
                                helperText={touched.assigneeTypes && errors.assigneeTypes}
                            />
                        </Box>
                        <Box
                            display='flex'
                            justifyContent='end'
                            mt='20px'
                            gap='20px'
                        >
                            <ButtonExt
                                type='submit'
                                value={
                                    promptPermissionUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    promptPermissionUpdateMutation.isLoading ||
                                    values.assigneeTypes?.length === 0
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default AdminAssigneeDetail