import React, { useState } from 'react'
import { Box } from '@mui/material'
import Header from '../../../components/Header'
import DatePickerExt from '../../../components/DatePickerExt'
import DataGridFilter, { SearchOptionsProp } from '../../../components/DataGridFilter'
import { fetchAudits, generateAuditReport } from '../../../actions/audit'
import { AuditLogFilterCustomFilterOptions } from '../../../interfaces/AuditType'
import SelectExt from '../../../components/Select'
import { auditPeriodOptions } from '../../../share/AuditConstrant'
import AdminAuditDetail from './detail'
import { useAuthQueryWithQueryFunction } from '../../../extensions/UseAuthQuery'
import { ApiError } from '../../../interfaces/ErrorType'
import { findUsers } from '../../../actions/user'
import AutocompleteExt from '../../../components/Autocomplete'

function AdminAuditLog() {

    const [customSearchOptions, setCustomSearchOptions] =
        useState<AuditLogFilterCustomFilterOptions>({
            period: '',
            startDate: undefined,
            endDate: undefined,
            username: '',
        })

    const expandRow = (row: any) => (
        <AdminAuditDetail wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchAudits(searchOptions)
    }

    const onDownloadEvent = (searchOptions: SearchOptionsProp) => {
        return generateAuditReport(searchOptions)
    }

    /**
     * Fetch users
     * */
    const userQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        string[]
        >('users', findUsers, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const availableUsersOptions = userQuery?.data?.map((user) => {
        return {
            value: user,
            label: user,
        }
    })

    const customSearchOptionsRenderer = () => (
        <>
            <SelectExt
                name='period'
                multiSelection={false}
                label='Period'
                selectedValue={customSearchOptions.period}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        period: value ? value : '',
                        startDate: undefined,
                        endDate: undefined
                    })
                }}
                options={auditPeriodOptions}
                isClearable={true}
            />

            {!customSearchOptions.period && (
              <>
                  <DatePickerExt
                      value={customSearchOptions.startDate}
                      onChange={(value) => {
                          console.log(value)
                          setCustomSearchOptions({
                              ...customSearchOptions,
                              startDate: value,
                          })
                      }}
                      max={customSearchOptions.endDate ? customSearchOptions.endDate : new Date().toISOString().slice(0, 10)}
                  />

                  <DatePickerExt
                      value={customSearchOptions.endDate}
                      onChange={(value) => {
                          setCustomSearchOptions({
                              ...customSearchOptions,
                              endDate: value,
                          })
                      }}
                      min={customSearchOptions.startDate}
                  />
              </>
            )}

            <AutocompleteExt
                name='username'
                multiSelection={false}
                label='Filtered by User'
                selectedValue={customSearchOptions.username}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        username: value ? value : '',
                    })
                }}
                options={availableUsersOptions}
                disableUnselectAll={false}
            />
        </>
    )

    const columns = [
        {
            dataField: 'username',
            text: 'Username',
            sort: true,
        },
        {
            dataField: 'prompt',
            text: 'Prompt',
            sort: false,
        },
        {
            dataField: 'queryAt',
            text: 'Query At',
            sort: true,
        },
    ]

    return (
        <Box m='20px'>
            <Header title='Admin Audit' />

            <DataGridFilter
                keyField='id'
                useQueryKey={`audits`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                reportTitle={'Audit-Log-Report'}
                onDownloadEvent={onDownloadEvent}
                customSearchOptions={customSearchOptions}
                resetCustomSearchOptions={setCustomSearchOptions}
                customSearchOptionsRenderer={customSearchOptionsRenderer()}
                searchFilterCols={2}
                expandRow={expandRow}
            />

        </Box>
    )
}

export default AdminAuditLog