import { Alert, Box, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import Header from '../../components/Header'
import { useMutation } from 'react-query'
import { login, signin, AuthToken } from '../../actions/auth'
import ButtonExt from '../../components/ButtonExt'
import { SigninRequest } from '../../interfaces/UserType'

const initialValues = {
    username: '',
    password: '',
}

const userSigninSchema = yup.object().shape({
    username: yup.string().required('required'),
    password: yup.string().required('required'),
})

const Form = () => {
    const isNonMobile = useMediaQuery('(min-width:600px)')

    /**
     * Mutate the signin request
     */
    const signinMutation = useMutation<AuthToken, Error, SigninRequest>(
        signin
    )

    const handleFormSubmit = (values: SigninRequest) => {
        signinMutation.mutate(values)
    }

    return (
        <Box m="200px" style={{ width: `500px` }}>
            <Header title="LOGIN" />

            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={userSigninSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0,1fr))"
                            sx={{
                                '& > div': {
                                    gridColumn: isNonMobile
                                        ? undefined
                                        : ' span 4',
                                },
                            }}
                        >
                            <>
                                <TextField
                                    fullWidth={true}
                                    variant="filled"
                                    type="text"
                                    label="Username"
                                    onChange={handleChange}
                                    value={values.username}
                                    name="username"
                                    error={
                                        !!touched.username && !!errors.username
                                    }
                                    helperText={
                                        touched.username && errors.username
                                    }
                                    sx={{ gridColumn: 'span 4' }}
                                />

                                <TextField
                                    fullWidth={true}
                                    variant="filled"
                                    type="password"
                                    label="Password"
                                    onChange={handleChange}
                                    value={values.password}
                                    name="password"
                                    error={
                                        !!touched.password && !!errors.password
                                    }
                                    helperText={
                                        touched.password && errors.password
                                    }
                                    sx={{ gridColumn: 'span 4' }}
                                />

                                {signinMutation.isError && (
                                    <Alert
                                        sx={{ gridColumn: 'span 4' }}
                                        severity="error"
                                    >
                                        Invalid credentials!
                                    </Alert>
                                )}
                                {signinMutation.isSuccess &&
                                    login(signinMutation.data)}
                            </>
                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <ButtonExt
                                type="submit"
                                value={
                                    signinMutation.isLoading
                                        ? 'Signing in...'
                                        : 'Sign in'
                                }
                                disabled={signinMutation.isLoading}
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default Form
