import { ColorModeContext, useMode } from './theme'
import { Alert, AlertTitle, CssBaseline, ThemeProvider } from '@mui/material'
import { Routes, Route, useLocation } from 'react-router-dom'
import Topbar from './scenes/global/Topbar'
import Sidebar from './scenes/global/Sidebar'
import Dashboard from './scenes/dashboard'
import Login from './scenes/login'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthScope, useAuth } from './actions/auth'
import { Provider } from 'react-redux'
import store from './redux/store'
import { loadCurrentConfigurations } from './actions/configuration'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import PromptQueryAssistant from './scenes/prompt/chat'
import AdminAuditLog from './scenes/admin/audit'
import UserContentSubmission from './scenes/content/submission'
import ContentSubmissionDetail from './scenes/content/submission/detail'
import React from 'react'
import AdminContentReview from './scenes/content/submission/admin'
import AdminPromptPermission from './scenes/admin/prompt/permission'
import AdminPrompt from './scenes/admin/prompt'
import AdminPromptDetail from './scenes/admin/prompt/detail'
import AuthTransitRoute from './scenes/transit/route'
import AdminPromptQueryReview from './scenes/admin/prompt/query/review'
import AdminAssignees from './scenes/admin/assignees'
import ManageMediaLibrary from './scenes/media'

function App() {
    const location = useLocation();
    const path = location.pathname;
    const [theme, colorMode] = useMode()
    const [isAuthenticated] = useAuth()
    const queryClient = new QueryClient()

    /**
     * Load current configurations upon startup
     */
    store.dispatch(loadCurrentConfigurations())

    return (
        <DndProvider backend={HTML5Backend}>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <ColorModeContext.Provider value={colorMode}>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <div className="app">
                                {path !== "/transit" && !isAuthenticated && <Login />}
                                {path !== "/transit" &&
                                    ((store.getState().user?.user?.user?.scopes && Array.from(store.getState().user.user.user.scopes, (scope: AuthScope) => scope.type).includes("HANA")))
                                    && isAuthenticated && <Sidebar />}


                                <main className="content">
                                    {isAuthenticated && (
                                        <>
                                            <Topbar />

                                            {((store.getState().user?.user?.user?.scopes && !Array.from(store.getState().user.user.user.scopes, (scope: AuthScope) => scope.type).includes("HANA"))) && (
                                                <Alert severity="error">
                                                    <AlertTitle>Access Denied: Insufficient Permissions</AlertTitle>
                                                    <p>We're sorry, but it seems that you don't have the necessary permissions to manage the hana console in the user interface.</p>
                                                    <p>Access to the hana console is restricted to authorized personnel only, as it involves critical settings and configurations for the system.</p>
                                                    <p>If you believe this is an error or you require access for specific tasks, please reach out to your system administrator or the designated support team. They will be able to assist you in obtaining the appropriate permissions or guide you through the necessary steps to achieve your desired objectives.</p>
                                                </Alert>
                                            )}

                                            {((store.getState().user?.user?.user?.scopes && Array.from(store.getState().user.user.user.scopes, (scope: AuthScope) => scope.type).includes("HANA"))) && (
                                                <>
                                                    <Routes>
                                                        <Route
                                                            path="/"
                                                            element={<Dashboard />}
                                                        />
                                                        <Route
                                                            path="/prompt/query"
                                                            element={
                                                                <PromptQueryAssistant />
                                                            }
                                                        />

                                                        {store.getState().user?.user?.user?.role && store.getState().user.user.user.role === 'ADMIN' && (
                                                            <>
                                                                <Route
                                                                    path="/admin/audit"
                                                                    element={
                                                                        <AdminAuditLog />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/admin/prompt/query/review"
                                                                    element={
                                                                        <AdminPromptQueryReview />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/admin/content/review"
                                                                    element={
                                                                        <AdminContentReview />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/admin/prompt"
                                                                    element={
                                                                        <AdminPrompt />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/admin/prompt/create"
                                                                    element={
                                                                        <AdminPromptDetail isNew={true} />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/admin/prompt/permission"
                                                                    element={
                                                                        <AdminPromptPermission />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/admin/assignees"
                                                                    element={
                                                                        <AdminAssignees />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/impersonate/content/submission/create"
                                                                    element={
                                                                        <ContentSubmissionDetail isNew={true} impersonate={true} />
                                                                    }
                                                                />
                                                            </>
                                                        )}

                                                        {store.getState().user?.user?.user?.role && store.getState().user.user.user.role === 'USER' && store.getState().user?.user?.user?.scopes && Array.from(store.getState().user.user.user.scopes, (scope: AuthScope) => scope.type).includes("HANA") && (
                                                            <>
                                                                <Route
                                                                    path="/content/submission"
                                                                    element={
                                                                        <UserContentSubmission />
                                                                    }
                                                                />

                                                                <Route
                                                                    path="/content/submission/create"
                                                                    element={
                                                                        <ContentSubmissionDetail isNew={true} />
                                                                    }
                                                                />
                                                            </>
                                                        )}

                                                        <Route
                                                            path="/media/library"
                                                            element={
                                                                <ManageMediaLibrary />
                                                            }
                                                        />

                                                    </Routes>
                                                </>
                                            )}
                                        </>
                                    )}

                                    <Routes>
                                        <Route path="/transit" element={<AuthTransitRoute />} />
                                    </Routes>
                                </main>
                            </div>
                        </ThemeProvider>
                    </ColorModeContext.Provider>
                </QueryClientProvider>
            </Provider>
        </DndProvider>
    )
}

export default App
