import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import Header from '../../../components/Header'
import DataGridFilter, { SearchOptionsProp } from '../../../components/DataGridFilter'
import {
    ContentSubmission,
    ContentSubmissionCustomFilterOptions,
    ContentType,
    Localization, Relationship,
} from '../../../interfaces/ContentType'
import ContentSubmissionDetail from './detail'
import { fetchContentSubmissions, loadCmsContentTypes, loadCmsLocalizations } from '../../../actions/content'
import SelectExt from '../../../components/Select'
import { contentSubmissionStateOptions } from '../../../share/ContentSubmissionConstant'
import AutocompleteExt from '../../../components/Autocomplete'
import { useAuthQueryWithQueryFunction } from '../../../extensions/UseAuthQuery'
import { ApiError } from '../../../interfaces/ErrorType'
import { PromptType } from '../../../interfaces/PromptType'
import { loadAvailablePromptTypes } from '../../../actions/prompt'
import { fetchDevelopmentStages } from '../../../actions/developmentStage'
import { loadCmsRelationships } from '../../../actions/relationship'
import CheckboxExt from '../../../components/Checkbox'

function UserContentSubmission() {

    const [change, setChange] = useState<string>()
    const [customSearchOptions, setCustomSearchOptions] =
        useState<ContentSubmissionCustomFilterOptions>({
            contentTypeId: undefined,
            milestoneChecklist: false,
            relationshipId: undefined,
            state: [],
            locale: undefined,
        })

    const expandRow = (row: ContentSubmission) => (
        <ContentSubmissionDetail isNew={false} wrapper={row} callback={setChange} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchContentSubmissions(searchOptions)
    }

    /**
     * Fetch localizations
     * */
    const cmsLocalizationQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        Localization[]
        >('cms-localizations', loadCmsLocalizations, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Available prompt type query
     */
    const availablePromptTypeQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        PromptType[]
        >('promptType', loadAvailablePromptTypes, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch relationships
     */
    const cmsRelationshipQuery = useAuthQueryWithQueryFunction<undefined,
        ApiError,
        Relationship[]>('cms-relationships', loadCmsRelationships, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch development stage list
     */
    const availableDevelopmentStageQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('developmentStages', fetchDevelopmentStages, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch content types
     * */
    const cmsContentTypeQuery = useAuthQueryWithQueryFunction<undefined,
        ApiError,
        ContentType[]>('cms-content-types', loadCmsContentTypes, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const [availableLocalizationsOptions, setAvailableLocalizationOptions] = useState<any[]>([])
    const [availablePromptOptions, setAvailablePromptOptions] = useState<any[]>([])
    const [developmentStages, setDevelopmentStages] = useState<any[]>([])
    const [availableContentTypesOptions, setAvailableContentTypesOptions] = useState<any[]>([])
    const [availableRelationshipOptions, setAvailableRelationshipOptions] = useState<any[]>([])

    useEffect(() => {
        if (cmsLocalizationQuery.data) {
            setAvailableLocalizationOptions(cmsLocalizationQuery.data.map(localization => {
                return {
                    value: localization.code,
                    label: localization.default ? `${localization.name} (Default)` : localization.name,
                }
            }))
        }
    }, [cmsLocalizationQuery.data])

    useEffect(() => {
        if (availablePromptTypeQuery.data) {
            setAvailablePromptOptions(availablePromptTypeQuery.data.map(prompt => {
                return {
                    value: prompt.id,
                    label: prompt.title,
                }
            }))
        }
    }, [availablePromptTypeQuery.data])

    useEffect(() => {
        if (availableDevelopmentStageQuery.data) {
            setDevelopmentStages(availableDevelopmentStageQuery.data)
        }
    }, [availableDevelopmentStageQuery.data])

    useEffect(() => {
        if (cmsContentTypeQuery.data) {
            setAvailableContentTypesOptions(cmsContentTypeQuery.data.map(contentType => {
                return {
                    value: contentType.id,
                    label: contentType.type,
                }
            }))
        }
    }, [cmsContentTypeQuery.data])

    useEffect(() => {
        if (cmsRelationshipQuery.data) {
            setAvailableRelationshipOptions(cmsRelationshipQuery.data.map(relationship => {
                return {
                    value: relationship.id,
                    label: relationship.name,
                }
            }))
        }
    }, [cmsRelationshipQuery.data])

    const customSearchOptionsRenderer = () => (
        <>
            <AutocompleteExt
                name='contentTypeId'
                multiSelection={false}
                label='Content Type'
                selectedValue={customSearchOptions.contentTypeId}
                options={availableContentTypesOptions}
                onSelect={(v) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        contentTypeId: v,
                    })
                }}
            />

            <CheckboxExt
                name="milestoneChecklist"
                label="Milestone Checklist Only"
                value={customSearchOptions.milestoneChecklist}
                onChange={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        milestoneChecklist: value,
                    })
                }}
            />

            <AutocompleteExt
                name='relationshipId'
                multiSelection={false}
                label='Relationship'
                selectedValue={customSearchOptions.relationshipId}
                options={availableRelationshipOptions}
                onSelect={(v) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        relationshipId: v,
                    })
                }}
            />

            <SelectExt
                name="state"
                multiSelection={true}
                label="Status"
                selectedValue={customSearchOptions.state}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        state: value,
                    })
                }}
                options={contentSubmissionStateOptions}
            />

            <AutocompleteExt
                name="locale"
                multiSelection={false}
                label="Locale"
                selectedValue={customSearchOptions.locale}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        locale: value,
                    })
                }}
                options={availableLocalizationsOptions}
            />
        </>
    )

    const columns = [
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
        },
        {
            dataField: 'developmentStageId',
            text: 'Development Stage',
            converter: (value: any) => {
                const converted = developmentStages.find( each => each.id === value);
                return converted ? converted.type : value;
            },
        },
        {
            dataField: 'weekOrMonth',
            text: 'Week/ Month',
            sort: true,
        },
        {
            dataField: 'weekOrMonthTo',
            text: 'Week/ Month To',
            sort: true,
        },
        {
            dataField: 'contentTypes',
            text: 'Content Type',
            converter: (value: any) => {
                const converted = availableContentTypesOptions.find( each => each.value === value[0].id);
                return converted ? converted.label : value;
            },
        },
        {
            dataField: 'assignee',
            text: 'Assignee',
            sort: false,
            converter: (value: any) => {
                return value ? `${value.username} ${value.displayName ? `(${value.displayName})` : ''}` : value;
            }
        },
        {
            dataField: 'createdBy',
            text: 'Created By',
            sort: false,
            converter: (value: any) => {
                return value ? `${value.username} ${value.displayName ? `(${value.displayName})` : ''}` : value;
            }
        },
        {
            dataField: 'createdAt',
            text: 'Created At',
            sort: true,
        },
        {
            dataField: 'updatedAt',
            text: 'Last Updated At',
            sort: true,
        },
        {
            dataField: 'state',
            text: 'Status',
            converter: (value: any) => {
                const converted = contentSubmissionStateOptions.find( each => each.value === value);
                return converted ? converted.label : value;
            },
            sort: true,
        },
    ]

    return (
        <Box m='20px'>
            <Header title='Content Submission' />

            <DataGridFilter
                keyField='id'
                useQueryKey={`content-submission`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                customSearchOptions={customSearchOptions}
                resetCustomSearchOptions={setCustomSearchOptions}
                change={change}
                customSearchOptionsRenderer={customSearchOptionsRenderer()}
                createPageUrl="/content/submission/create"
                searchFilterCols={2}
                expandRow={expandRow}
            />

        </Box>
    )
}

export default UserContentSubmission