import {
    Box,
    Dialog,
    ToggleButtonGroup,
    ToggleButton,
    useTheme, Tooltip,
} from '@mui/material'
import React, { useState } from 'react'
import { tokens } from '../theme'
import CloseIcon from '@mui/icons-material/Close'
import MediaLibrary from './MediaLibrary'
import InfoIcon from '@mui/icons-material/Info';
import GenerateImage from './GenerateImage'
import GenerateVideo from './GenerateVideo'
import { ContentSubmissionRequest } from '../interfaces/ContentType'

interface AddMediaModalProps {
    open: boolean
    onClose: (value: string) => void
    contentSubmissionRequestId: string
    mediaType: string
    onChangeEvent: (mediaType: string, key: string, promptUsed?: string) => void
    contentSubmissionRequest: ContentSubmissionRequest
}

const AddMediaModal = ({
    open,
    onClose,
    contentSubmissionRequestId,
    mediaType,
    contentSubmissionRequest,
    onChangeEvent,
}: AddMediaModalProps) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [toggleButton, setToggleButton] = useState<string | null>(
        'media-library'
    )

    const handleToggleButton = (
        event: React.MouseEvent<HTMLElement>,
        newTab: string | null
    ) => {
        if (newTab === null && toggleButton !== null) {
            return
        }
        setToggleButton(newTab)
    }
    return (
        <Dialog
            onClose={onClose}
            open={open}
            maxWidth="md"
            PaperProps={{
                sx: {
                    minHeight: '500px',
                    backgroundColor:
                        theme.palette.mode === 'dark'
                            ? colors.primary[500]
                            : '',
                    padding: 3,
                },
            }}
        >
            {mediaType === 'MEDIA' && (
                <Box display={'flex'} flexDirection={'column'} gap={2}>
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <ToggleButtonGroup
                            value={toggleButton}
                            exclusive
                            onChange={handleToggleButton}
                            aria-label="tab"
                        >
                            <ToggleButton
                                value="media-library"
                                aria-label="medialibrary-tab"
                            >
                                Media Library
                            </ToggleButton>
                            <ToggleButton
                                disabled={
                                    contentSubmissionRequestId ? false : true
                                }
                                value="image-generation"
                                aria-label="generate-tab"
                            >
                                Image Generation
                            </ToggleButton>
                            <Tooltip title="Kindly remember to save your latest edits before generating an image. This ensures that you have the most up-to-date content included in the generated image." placement="top">
                                <InfoIcon />
                            </Tooltip>
                        </ToggleButtonGroup>

                        <CloseIcon onClick={() => {
                            setToggleButton('media-library')
                            onClose('')
                        }} />
                    </Box>

                    {toggleButton === 'media-library' && (
                        <MediaLibrary
                            mediaType={mediaType}
                            onChangeEvent={onChangeEvent}
                            onClose={onClose}
                        />
                    )}
                    {toggleButton === 'image-generation' &&
                        contentSubmissionRequestId && (
                            <GenerateImage
                                contentSubmissionRequestId={
                                    contentSubmissionRequestId
                                }
                                mediaType={mediaType}
                                onClose={onClose}
                                onChangeEvent={onChangeEvent}
                            />
                        )}
                </Box>
            )}
            {mediaType === 'BANNER' && (
                <Box display={'flex'} flexDirection={'column'} gap={2}>
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <ToggleButtonGroup
                            value={toggleButton}
                            exclusive
                            onChange={handleToggleButton}
                            aria-label="tab"
                        >
                            <ToggleButton
                                value="media-library"
                                aria-label="medialibrary-tab"
                            >
                                Media Library
                            </ToggleButton>
                            <ToggleButton
                                disabled={
                                    contentSubmissionRequestId ? false : true
                                }
                                value="image-generation"
                                aria-label="generate-tab"
                            >
                                Image Generation
                            </ToggleButton>
                            <Tooltip title="Kindly remember to save your latest edits before generating an image. This ensures that you have the most up-to-date content included in the generated image." placement="top">
                                <InfoIcon />
                            </Tooltip>
                        </ToggleButtonGroup>

                        <CloseIcon onClick={() => {
                            setToggleButton('media-library')
                            onClose('')
                        }} />
                    </Box>

                    {toggleButton === 'media-library' && (
                        <MediaLibrary
                            mediaType={mediaType}
                            onChangeEvent={onChangeEvent}
                            onClose={onClose}
                        />
                    )}
                    {toggleButton === 'image-generation' &&
                        contentSubmissionRequestId && (
                            <GenerateImage
                                contentSubmissionRequestId={
                                    contentSubmissionRequestId
                                }
                                mediaType={mediaType}
                                onClose={onClose}
                                onChangeEvent={onChangeEvent}
                            />
                        )}
                </Box>
            )}
            {mediaType === 'VIDEO' && (
                <Box display={'flex'} flexDirection={'column'} gap={2}>
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <ToggleButtonGroup
                            value={toggleButton}
                            exclusive
                            onChange={handleToggleButton}
                            aria-label="tab"
                        >
                            <ToggleButton
                                value="media-library"
                                aria-label="medialibrary-tab"
                            >
                                Media Library
                            </ToggleButton>
                            <ToggleButton
                                disabled={
                                    contentSubmissionRequestId ? false : true
                                }
                                value="video-generation"
                                aria-label="generate-tab"
                            >
                                Video Generation
                            </ToggleButton>
                            <Tooltip title="Kindly remember to save your latest edits before generating a video. This ensures that you have the most up-to-date content included in the generated video." placement="top">
                                <InfoIcon />
                            </Tooltip>
                        </ToggleButtonGroup>

                        <CloseIcon onClick={() => {
                            setToggleButton('media-library')
                            onClose('')
                        }} />
                    </Box>

                    {toggleButton === 'media-library' && (
                        <MediaLibrary mediaType={mediaType} onChangeEvent={onChangeEvent} onClose={onClose} />
                    )}
                    {toggleButton === 'video-generation' && <GenerateVideo />}
                </Box>
            )}
        </Dialog>
    )
}

export default AddMediaModal
