import { v4 as uuidv4 } from 'uuid'
import React, { FunctionComponent, useMemo } from 'react'
import { styled } from '@mui/system'
import { Button } from '@mui/material'

const ButtonExt: FunctionComponent<ButtonProp> = ({
    type,
    className,
    name,
    backgroundColor,
    icon,
    value,
    disabled = false,
    style,
    onBlurEvent,
    onClickEvent,
}) => {
    const uuid = useMemo(() => uuidv4(), [])
    const StyledButton = styled(Button)({
        backgroundColor: `${backgroundColor ? backgroundColor : '#4cceac'} !important`,
        color: `rgba(0, 0, 0, 0.87) !important`,

        ':hover': {
            backgroundColor: `${backgroundColor ? backgroundColor : '#4cceac'} !important`,
            filter: `brightness(80%) !important`,
            cursor: 'pointer !important',
        },

        ':disabled': {
            backgroundColor: `#cccccc !important`,
        },
    })

    const onInternalBlurEvent = async (e: any) => {
        if (onBlurEvent) {
            onBlurEvent(e)
        }
    }

    const onInternalClickEvent = async (e: any) => {
        if (onClickEvent) {
            onClickEvent(e)
        }
    }

    return (
        <StyledButton
            type={type}
            className={className}
            key={uuid}
            style={style}
            name={name}
            variant="contained"
            disabled={disabled}
            onBlur={onInternalBlurEvent}
            onClick={onInternalClickEvent}
        >
            {icon} {value}
        </StyledButton>
    )
}

interface ButtonProp {
    type?: 'button' | 'submit' | 'reset' | undefined
    className?: string
    backgroundColor?: string
    name?: string
    icon?: any
    value: string
    disabled?: boolean
    style?: React.CSSProperties | undefined
    onBlurEvent?: (event: any) => void // Handle event upon onBlur
    onClickEvent?: (e: any) => void // Handle event upon onclick
}

export default ButtonExt
