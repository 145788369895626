import SecureAxios from '../extensions/SecureAxios'
import { UPDATE_TABLE_DATA_ITEM } from '../redux/actionType'
import store from '../redux/store'

export async function loadCmsLocalizations() {
    const { data } = await SecureAxios.get(`/cms/localization`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data.data
}

export async function loadCmsContentTypes() {
    const { data } = await SecureAxios.get(`/cms/content/type`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data.data
}

export const fetchContentSubmissions = async (searchOptions) => {
    let contentSubmissionSearchUrl = `/hana/content/submission?keyword=${searchOptions.keyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        let sortField = searchOptions.sortOptions.sortField
        if (sortField === 'weekOrMonth') {
            sortField = 'due'
        }

        if (sortField === 'weekOrMonthTo') {
            sortField = 'dueTo'
        }

        contentSubmissionSearchUrl += `&sort=${sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    if (searchOptions.customSearchOptions?.state?.length) {
        contentSubmissionSearchUrl += `&state=${searchOptions.customSearchOptions.state.join(
            ','
        )}`
    }

    if (searchOptions.customSearchOptions?.saveForLater) {
        contentSubmissionSearchUrl += `&saveForLater=${searchOptions.customSearchOptions?.saveForLater}`
    }

    if (searchOptions.customSearchOptions?.locale) {
        contentSubmissionSearchUrl += `&locale=${searchOptions.customSearchOptions.locale}`
    }

    if (searchOptions.customSearchOptions?.username) {
        contentSubmissionSearchUrl += `&username=${searchOptions.customSearchOptions.username}`
    }

    if (searchOptions.customSearchOptions?.promptUsedIds?.length) {
        contentSubmissionSearchUrl += `&promptUsedIds=${searchOptions.customSearchOptions.promptUsedIds.join(
            ','
        )}`
    }

    if (searchOptions.customSearchOptions?.contentTypeId) {
        contentSubmissionSearchUrl += `&contentTypeId=${searchOptions.customSearchOptions.contentTypeId}`
    }

    if (searchOptions.customSearchOptions?.milestoneChecklist) {
        contentSubmissionSearchUrl += `&milestoneChecklist=true`
    }

    if (searchOptions.customSearchOptions?.relationshipId) {
        contentSubmissionSearchUrl += `&relationshipId=${searchOptions.customSearchOptions.relationshipId}`
    }

    const { data } = await SecureAxios.get(contentSubmissionSearchUrl)
    return data
}

/**
 * Generate Content Submission Report
 * @param searchOptions
 * @returns {Promise<any>}
 */
export const generateContentSubmissionReport = async (searchOptions) => {
    let contentSubmissionReportUrl = `/hana/admin/content/submission/csv/report?keyword=${searchOptions.keyword}`

    if (searchOptions.customSearchOptions?.state?.length) {
        contentSubmissionReportUrl += `&state=${searchOptions.customSearchOptions.state.join(
            ','
        )}`
    }

    if (searchOptions.customSearchOptions?.locale) {
        contentSubmissionReportUrl += `&locale=${searchOptions.customSearchOptions.locale}`
    }

    if (searchOptions.customSearchOptions?.username) {
        contentSubmissionReportUrl += `&username=${searchOptions.customSearchOptions.username}`
    }

    if (searchOptions.customSearchOptions?.promptUsedIds?.length) {
        contentSubmissionReportUrl += `&promptUsedIds=${searchOptions.customSearchOptions.promptUsedIds.join(
            ','
        )}`
    }

    if (searchOptions.customSearchOptions?.contentTypeId) {
        contentSubmissionReportUrl += `&contentTypeId=${searchOptions.customSearchOptions.contentTypeId}`
    }

    if (searchOptions.customSearchOptions?.milestoneChecklist) {
        contentSubmissionReportUrl += `&milestoneChecklist=true`
    }

    if (searchOptions.customSearchOptions?.relationshipId) {
        contentSubmissionReportUrl += `&relationshipId=${searchOptions.customSearchOptions.relationshipId}`
    }

    const { data } = await SecureAxios.get(contentSubmissionReportUrl)
    return data
}

/**
 * An action to save a content
 */
export async function saveContent(content) {
    if (content.id) {
        return updateContent(content)
    } else {
        if (content.state === 'REQUEST_APPROVAL') {
            const data = await createContent(content)
            return updateContent({
                ...content,
                id: data.id,
            })
        } else {
            return createContent(content)
        }
    }
}

/**
 * An action to bulk approve, reject or request change for the selected contents
 */
export async function bulkApproval(bulkRequest) {
    const requestBody = {
        contentSubmissionIds: bulkRequest.contentSubmissionIds,
        state: bulkRequest.state,
        assignee: bulkRequest.assignee,
    }
    await SecureAxios.put(
        `/hana/admin/content/submission/bulk/approval`,
        requestBody,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
}

/**
 * An action to bulk reassign based on the selected contents
 */
export async function bulkReassign(bulkRequest) {
    const requestBody = {
        contentSubmissionIds: bulkRequest.contentSubmissionIds,
        assignee: bulkRequest.assignee,
    }
    await SecureAxios.put(
        `/hana/admin/content/submission/bulk/reassign`,
        requestBody,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
}

/**
 * An action to save a comment
 */
export async function saveComment(content) {
    const requestBody = {
        comment: content.comment,
    }

    const { data } = await SecureAxios.post(
        `/hana/content/submission/${content.id}/comment`,
        requestBody,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    return data.data
}

async function createContent(content) {
    const requestBody = {
        ...content,
    }
    const { data } = await SecureAxios.post(
        `/hana/content/submission`,
        requestBody,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    return data.data
}

async function updateContent(content) {
    const requestBody = {
        ...content,
    }

    const { data } = await SecureAxios.put(
        `/hana/content/submission/${content.id}/update`,
        requestBody,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: {
            key: `${
                store.getState().user?.user?.user?.role &&
                store.getState().user?.user?.user?.role?.toLowerCase() ===
                    'admin'
                    ? 'admin-content-submission-review'
                    : 'content-submission'
            }`,
            keyField: 'id',
            data: data.data,
        },
    })

    return data.data
}

export async function generatePromptScript(contentSubmissionId) {
    const { data } = await SecureAxios.post(
        `/hana/content/submission/${contentSubmissionId}/image/script/generate`,
        null,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    return data
}

/**
 * An action to load all categories
 */
export async function loadCategories() {
    const { data } = await SecureAxios.get(`/file/categories/`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
    return data.data
}

export async function createCategory(categoryName) {
    const requestBody = {
        name: categoryName,
    }
    try {
        const response = await SecureAxios.post('/file/category', requestBody, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response?.data
    } catch (error) {
        throw new Error('Error creating new category: ' + error)
    }
}

export async function updateFile(request) {
    const requestBody = {
        fileStorageCategoryId: request?.data?.fileStorageCategoryId,
        name: request?.data?.name,
    }
    try {
        const response = await SecureAxios.put(
            `/file/upload/${request?.key}/metadata/update`,
            requestBody,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        return response?.data
    } catch (error) {
        throw new Error('Error creating new category: ' + error)
    }
}

/**
 * An action to load all image of a category
 */
export async function loadImages(catId, page, keyword = '', size ) {
    const params = {
        fileStorageCategoryIds: catId,
        page: page,
        size: size,
    }

    console.log(params)

    if (keyword) {
        params.keyword = keyword
    }

    const { data } = await SecureAxios.get(`/file/upload/search`, {
        params: params,
        headers: {
            'Content-Type': 'application/json',
        },
    })
    return data
}

export async function uploadFile(file) {
    try {
        const formData = new FormData()
        formData.append('file', file.originFileObj)

        const response = await SecureAxios.post('/file/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        return response.data
    } catch (error) {
        throw new Error('Error uploading file: ' + error)
    }
}

export async function deleteFileUpload(key) {
    try {
        const response = await SecureAxios.delete(`/file/upload/${key}/delete`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    } catch (error) {
        throw new Error('Error deleting file: ' + error)
    }
}

export async function generateImage(image) {
    const requestBody = { ...image }
    const { data } = await SecureAxios.post(
        `/hana/content/submission/image/api/generate/async`,
        requestBody,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    const eventId = data.eventId

    // Retries till the image is generated
    return await new Promise((resolve, reject) => {
        const interval = setInterval(async () => {
            try {
                const update = await generateImageStatusUpdate(eventId)
                if (update.data?.state === 'COMPLETE') {
                    clearInterval(interval)
                    resolve(update.data?.result)
                }
            } catch (error) {
                clearInterval(interval)
                reject(error)
            }
        }, 10000)
    })
}

/**
 * An action to fetch event update
 * @param searchOptions
 * @returns {Promise<*>}
 */
export const generateImageStatusUpdate = async (eventId) => {
    const { data } = await SecureAxios.get(`/hana/content/submission/image/api/generate/async/${eventId}/status/interval`)
    return data
}

export async function assignImage(contentSubmissionRequestId, mediaType, selectGeneratedImage, modifiedPrompt) {
    const requestBody = {
        assignmentType: mediaType,
        url: selectGeneratedImage,
        modifiedPrompt: modifiedPrompt,
    }
    const { data } = await SecureAxios.put(
        `/hana/content/submission/${contentSubmissionRequestId}/image/assign`,
        requestBody,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    return data
}

export async function downloadImage(imageUrl) {
    const response = await SecureAxios.get(
        `/hana/content/submission/image/proxy/download?imageUrl=${encodeURIComponent(imageUrl)}`,
        { responseType: 'blob' }
    );

    if (response.status !== 200) {
        throw new Error('Network response was not ok');
    }

    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = imageUrl.split('/').pop() || 'downloaded_image.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
}

export async function getFile(key) {
    const { data } = await SecureAxios.get(`/file/upload/${key}`,
        { headers: { 'Content-Type': 'application/json' } });
    return data
}