import SecureAxios from '../extensions/SecureAxios'
import store from '../redux/store'
import { UPDATE_TABLE_DATA_ITEM } from '../redux/actionType'

export const fetchPromptPermissions = async (searchOptions) => {
    let promptPermissionsUrl = `/hana/admin/user/prompt/permissions?keyword=${searchOptions.keyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        promptPermissionsUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    const { data } = await SecureAxios.get(promptPermissionsUrl)
    return data
}

export async function updatePromptPermission(promptPermission) {
    const requestBody = {
        ...promptPermission,
    }

    const { data } = await SecureAxios.put(`/hana/admin/user/prompt/permissions/${promptPermission.id}/update`, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: { key: 'admin-prompt-permission', keyField: 'id', data: data.data },
    })

    return data.data
}