import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Box, FormHelperText, IconButton, InputLabel, TextField } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AccordionExt from './AccordionExt'
import TagInputExt from './TagInput'
import ButtonExt from './ButtonExt'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AlertDialog from './AlertDialog'

/**
 * Extended React component to render a specific reference item html tag, event function binding, and any
 */
const ReferenceItems: FunctionComponent<ReferenceItemProp> = ({
                                                                  label,
                                                                  values,
                                                                  onBlurEvent,
                                                                  onChangeEvent,
                                                                  error,
                                                                  helperText,
                                             }) => {
    let uuid = useMemo(() => uuidv4(), [])
    const [valueChanges, setValueChanges] = useState<ReferenceItem[]>(values || [])
    const [showAlert, setShowAlert] = useState({
        enable: false,
        message: '',
    });

    useEffect(() => {
        setValueChanges(values || []);
    }, [values]);

    const onInternalBlurEvent = async (event: any) => {
        if (onBlurEvent) {
            onBlurEvent(event)
        }
    }

    const onInternalChangeEvent = async (v: ReferenceItem[]) => {
        if (onChangeEvent) {
            onChangeEvent(v)
        }
    }

    const validateError = (valueChangeItem: ReferenceItem) => {
        if (valueChangeItem.text?.trim()?.length === 0 && valueChangeItem.urls?.length === 0) {
            return 'Text and at least one URL are required'
        }

        if (valueChangeItem.text?.trim()?.length === 0 && valueChangeItem.urls?.length > 0) {
            return 'Text is required'
        }

        if (valueChangeItem.text?.trim()?.length > 0 && valueChangeItem.urls?.length === 0) {
            return 'Specify at least one URL'
        }

        return ''
    }

    // Function to check if a string is a valid HTTPS URL
    const isValidHttpsUrl = (url: string) => {
        // Regular expression for a valid HTTPS URL
        const urlRegex = /\b\w+:\/\/[^\t\n\r\f ]+\b/;
        return urlRegex.test(url);
    };

    return (
        <FormHelperText onBlur={onInternalBlurEvent} error={error}>
            <Box key={`reference-item-${uuid}`}>
                {label && (
                    <InputLabel
                        style={{ fontSize: `small`, paddingLeft: `15px`, paddingBottom: `10px` }}
                    >
                        {label}
                    </InputLabel>
                )}

                {valueChanges.map((valueChangeItem, valueChangeIndex) => {
                    return (
                        <Box key={`accordion-box-${valueChangeIndex}`}>
                            <AccordionExt
                                key={`accordion-${valueChangeIndex}`}
                                title={valueChangeItem.text ? valueChangeItem.text : ``}
                                defaultExpanded={valueChangeItem.defaultExpanded}
                                errorMessage={
                                    validateError(valueChangeItem)
                                }
                                error={!valueChangeItem.text.trim() || valueChangeItem.urls?.length === 0}
                                component={
                                    <Box display="grid"
                                         gap="30px"
                                         gridTemplateColumns="repeat(1, minmax(0,1fr))"
                                    >
                                        <TextField
                                            key={`referenceText-${valueChangeIndex}`}
                                            variant="filled"
                                            type="text"
                                            label="Reference text"
                                            value={valueChangeItem.text}
                                            onChange={async (e) => {
                                                const cloneValues = [...valueChanges]
                                                cloneValues[valueChangeIndex].text = e.target.value
                                                setValueChanges(cloneValues)
                                                await onInternalChangeEvent(cloneValues)
                                            }}
                                            name={`referenceText-${valueChangeIndex}`}
                                        />
                                        <Box
                                            display="grid"
                                            gap="30px"
                                            gridTemplateColumns="1fr 2em"
                                        >
                                            <TagInputExt
                                                key={`referenceUrls-${valueChangeIndex}`}
                                                name={`referenceUrls-${valueChangeIndex}`}
                                                placeholder={`Enter URL`}
                                                values={valueChangeItem.urls.map((each) => {
                                                    return {id: each, text: each}
                                                })}
                                                onItemAdd={async (v) => {
                                                    // Validate HTTPS URL before adding
                                                    if (isValidHttpsUrl(v.id)) {
                                                        const cloneValues = [...valueChanges];
                                                        cloneValues[valueChangeIndex].urls.push(v.id);
                                                        setValueChanges(cloneValues);
                                                        await onInternalChangeEvent(cloneValues);
                                                    } else {
                                                        setShowAlert({
                                                            enable: true,
                                                            message: 'Oops! The provided URL is invalid. Please enter a valid URL.'
                                                        })
                                                    }
                                                }}
                                                onItemDelete={async (index) => {
                                                    const cloneValues = [...valueChanges]
                                                    cloneValues[valueChangeIndex].urls.splice(index, 1)
                                                    setValueChanges(cloneValues)
                                                    await onInternalChangeEvent(cloneValues)
                                                }}
                                                allowDragDrop={true}
                                                onItemDrag={async (v, currentIndex, dragIndex) => {
                                                    const cloneValues = [...valueChanges]
                                                    const item = cloneValues[currentIndex]
                                                    cloneValues.splice(currentIndex, 1)
                                                    cloneValues.splice(dragIndex, 0, item)
                                                    setValueChanges(cloneValues)
                                                    await onInternalChangeEvent(cloneValues)
                                                }}
                                            />

                                            {valueChangeItem.urls?.length > 0 && (
                                                <CopyToClipboard text={valueChangeItem.urls!!.join("\n")}>
                                                    <IconButton color="secondary">
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </CopyToClipboard>
                                            )}
                                        </Box>
                                    </Box>
                                }
                                actionRenderer={
                                    <Box>
                                        <ButtonExt
                                            style={{padding: `2px`, minWidth: `2px`}}
                                            type="button"
                                            value=""
                                            icon={<RemoveIcon />}
                                            onClickEvent={async () => {
                                                const cloneValues = [...valueChanges]
                                                cloneValues.splice(valueChangeIndex, 1)
                                                setValueChanges(cloneValues)
                                                await onInternalChangeEvent(cloneValues)
                                            }}
                                        />
                                    </Box>
                                }
                            />
                        </Box>
                    )
                })}

                <Box style={{padding: `0`, marginRight: `5.5em`, marginTop: `1em`}} textAlign='right'>
                    <ButtonExt
                        style={{padding: `2px`, minWidth: `2px`}}
                        type="button"
                        value=""
                        icon={<AddIcon />}
                        onClickEvent={async () => {
                            const cloneValues = [...valueChanges]
                            cloneValues.push({text: '', urls: [], defaultExpanded: true})
                            setValueChanges(cloneValues)
                            await onInternalChangeEvent(cloneValues)
                        }}
                    />
                </Box>
            </Box>

            <AlertDialog open={showAlert.enable} message={showAlert.message} onAcknowledge={() => {setShowAlert({enable: false, message: ''})} }/>

            {helperText}
        </FormHelperText>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface ReferenceItemProp {
    label: string
    values: ReferenceItem[] | undefined
    onBlurEvent?: (event: any) => void // Handle event upon onBlur
    onChangeEvent?: (v: ReferenceItem[]) => void // Handle event upon onSelect
    error?: boolean | undefined
    helperText?: React.ReactNode
}

export interface ReferenceItem {
    text: string
    urls: string[]
    defaultExpanded?: boolean | undefined
}

export default ReferenceItems
