import SecureAxios from '../extensions/SecureAxios'

export async function getTemplates() {
    const { data } = await SecureAxios.get(`/tinymce/templates`)
    return data
}

export async function getTemplateContent(templateId) {
    const { data } = await SecureAxios.get(`/tinymce/templates/${templateId}`)
    return data
}

export async function saveTemplateCategory(title) {
    const { data } = await SecureAxios.post(`/admin/tinymce/templates/category`, {
        title
    })
    return data
}

export async function renameTemplateCategory(categoryId, title) {
    await SecureAxios.put(`/admin/tinymce/templates/category/${categoryId}`, {
        title
    })
}

export async function moveTemplateCategory(oldCategoryId, newCategoryId) {
    await SecureAxios.patch(`/admin/tinymce/templates/category/${oldCategoryId}/move`, {
        newCategoryId
    })
}

export async function deleteTemplateCategory(categoryId) {
    await SecureAxios.delete(`/admin/tinymce/templates/category/${categoryId}`)
}

export async function saveTemplate(title, content, categoryId) {
    const { data } = await SecureAxios.post(`/tinymce/templates`, {
        title,
        content,
        categoryId
    })
    return data
}

export async function renameTemplate(templateId, title) {
    await SecureAxios.put(`/tinymce/templates/${templateId}`, {
        title,
    })
}

export async function moveTemplate(templateId, newCategoryId) {
    await SecureAxios.patch(`/tinymce/templates/${templateId}/move`, {
        newCategoryId
    })
}

export async function deleteTemplate(templateId) {
    await SecureAxios.delete(`/tinymce/templates/${templateId}`)
}

export async function assist(request, options = {}) {
    const { signal } = options;

    const requestBody = {
        ...request,
    }
    const { data } = await SecureAxios.post(`/tinymce/ai/assist/async`, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
        signal, // Pass the signal for cancellation
    })

    const eventId = data.eventId

    const update = await getRequestStatus(eventId, signal);

    return update.data;
}

const getRequestStatus = async (eventId, signal) => {
    const { data } = await SecureAxios.get(
        `/tinymce/ai/assist/async/${eventId}/status`, {
            signal, // Pass the signal for cancellation
        })
    return data
}

export const getRevisions = async (contentId) => {
    const { data } = await SecureAxios.get(`/tinymce/revisions/content/${contentId}`)
    return data.data
}