import React, { FunctionComponent, useEffect, useState } from 'react'
import { Box, IconButton, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useMutation } from 'react-query'
import { ApiError } from '../../../../interfaces/ErrorType'
import AutocompleteExt from '../../../../components/Autocomplete'
import { useAuthQueryWithQueryFunction } from '../../../../extensions/UseAuthQuery'
import {
    AdminPromptType,
    AdminPromptTypeRequest,
    AdminPromptTypeWrapper,
    UserType,
} from '../../../../interfaces/PromptType'
import { createUserType, loadAvailableUserTypes, savePrompt } from '../../../../actions/prompt'
import ButtonExt from '../../../../components/ButtonExt'
import TextareaAutosizeExt from '../../../../components/TextareaAutosize'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Header from '../../../../components/Header'
import ErrorMessage from '../../../../components/ErrorMessage'
import { useNavigate } from 'react-router-dom'

const promptSchema = yup.object().shape({
    title: yup.string().required('required'),
    description: yup.string().required('required').max(2500),
    userTypeIds: yup.array().min(1).required('required'),
})

const AdminPromptDetail: FunctionComponent<AdminPromptTypeWrapper> = ({
                                                                          isNew,
                                                                          wrapper,
                                                                      }) => {

    const navigate = useNavigate()
    const [adminPromptTypeRequest, setAdminPromptTypeRequest] = useState<AdminPromptTypeRequest>(wrapper?.id ? {
        id: wrapper!!.id,
        title: wrapper!!.title,
        description: wrapper!!.description,
        userTypeIds: wrapper!!.userTypes.map(userType => userType.id),
    } : {
        id: undefined,
        title: '',
        description: '',
        userTypeIds: [],
    })

    /**
     * Available user type query
     */
    const availableUserTypeQuery = useAuthQueryWithQueryFunction<undefined,
        ApiError,
        UserType[]>('userType', loadAvailableUserTypes, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    useEffect(() => {
        if (availableUserTypeQuery.data) {
            setAvailableUserTypeOptions(availableUserTypeQuery.data?.map(
                (userType) => {
                    return {
                        value: userType.id,
                        label: userType.type,
                    }
                },
            ))
        }
    }, [availableUserTypeQuery.data])

    const [availableUserTypeOptions, setAvailableUserTypeOptions] = useState<any[]>()

    /**
     * Invoke an action to update prompt
     * @param {*} e - event
     */
    const onSave = (values: AdminPromptTypeRequest) => {
        promptPermissionCreateOrUpdateMutation.mutate(values, {
            onSuccess: (data) => {
                console.log(data)
                const adminPromptType = {
                    id: data.id,
                    title: data.title,
                    description: data.description,
                    userTypeIds: data.userTypes.map(userType => userType.id)
                }
                setAdminPromptTypeRequest(adminPromptType)
                values = adminPromptType
            },
        })
    }

    /**
     * Mutate prompt permission update
     */
    const promptPermissionCreateOrUpdateMutation = useMutation<AdminPromptType, ApiError, AdminPromptTypeRequest>(
        savePrompt,
    )

    if (promptPermissionCreateOrUpdateMutation.isSuccess && isNew) {
        navigate(`/admin/prompt`)
    }

    /**
     * Page containing prompt detail page
     */
    return (
        <Box m='20px'>
            {isNew && (
                <>
                    <Box
                        display="flex"
                        justifyContent="start"
                        mt="20px"
                        style={{ padding: `10px` }}
                    >
                        <IconButton
                            color="secondary"
                            onClick={() => navigate(`/admin/prompt`)}
                        >
                            <ArrowBackIcon /> Back
                        </IconButton>
                    </Box>

                    <Header title="Create New Prompt" />
                </>
            )}

            <Box style={{ marginBottom: `2em` }}>
                {promptPermissionCreateOrUpdateMutation.isError && (
                    <ErrorMessage error={promptPermissionCreateOrUpdateMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={adminPromptTypeRequest}
                validationSchema={promptSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display='grid'
                            gap='30px'
                            gridTemplateColumns='repeat(1, minmax(0,1fr))'
                        >
                            {values.id && (
                                <TextField
                                    variant='filled'
                                    type='text'
                                    label='Id'
                                    value={values.id}
                                    name='id'
                                />
                            )}

                            <TextField
                                variant="filled"
                                type="text"
                                label="Title"
                                onChange={handleChange}
                                value={values.title}
                                name="title"
                                error={!!touched.title && !!errors.title}
                                helperText={touched.title && errors.title}
                            />

                            <TextareaAutosizeExt
                                name="description"
                                label={`Description [Maximum 2500 characters (${2500 - values.description.length} remaining) ]`}
                                value={values.description}
                                onChangeEvent={handleChange}
                                minRows={20}
                                maxRows={20}
                                maxLength={2500}
                                error={!!touched.description && !!errors.description}
                                helperText={touched.description && errors.description}
                            />

                            {availableUserTypeOptions && availableUserTypeOptions.length > 0 && (
                                <AutocompleteExt
                                    name='userTypeIds'
                                    multiSelection={true}
                                    label='User Types'
                                    selectedValue={values.userTypeIds}
                                    options={availableUserTypeOptions}
                                    onSelect={(v) => {
                                        setAdminPromptTypeRequest({
                                            ...adminPromptTypeRequest,
                                            userTypeIds: v,
                                        })
                                        values.userTypeIds = v
                                    }}
                                    onCreateAction={async (v) => {
                                        const data = await createUserType(v)
                                        return data
                                    }}
                                    onCreateActionCallback={(v: any) => {
                                        setAvailableUserTypeOptions([
                                            ...availableUserTypeOptions!!,
                                            {
                                                label: v.type,
                                                value: v.id
                                            }
                                        ])

                                        const updateSelectedValue = [...adminPromptTypeRequest.userTypeIds, v.id]
                                        setAdminPromptTypeRequest({
                                            ...adminPromptTypeRequest,
                                            userTypeIds: updateSelectedValue
                                        })
                                        values.userTypeIds = updateSelectedValue
                                    }}
                                    error={!!touched.userTypeIds && !!errors.userTypeIds}
                                    helperText={touched.userTypeIds && errors.userTypeIds}
                                />
                            )}
                        </Box>
                        <Box
                            display='flex'
                            justifyContent='end'
                            mt='20px'
                            gap='20px'
                        >
                            <ButtonExt
                                type='submit'
                                value={
                                    promptPermissionCreateOrUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    promptPermissionCreateOrUpdateMutation.isLoading ||
                                    values.userTypeIds?.length === 0
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default AdminPromptDetail