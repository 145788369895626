import { CLEAR_CHAT, ADD_CHAT_CONVERSATION, UPDATE_CHAT_SELECTED_OPTIONS, CLEAR_ALL_CHAT } from '../actionType'
import { getValueByKey, removeKey, updateValueByKey } from '../../extensions/EncryptStorage'

export const CURRENT_SELECTED_CHATBOT_OPTIONS = 'CURRENT_SELECTED_CHATBOT_OPTIONS'
export const CURRENT_USER_CHATBOT_HISTORIES = 'CURRENT_USER_CHATBOT_HISTORIES'

const initialState = {
    selectedOptions: getValueByKey(CURRENT_SELECTED_CHATBOT_OPTIONS) || [],
    data: getValueByKey(CURRENT_USER_CHATBOT_HISTORIES) || []
}

export default function chatbotConversation(state = initialState, action) {
    switch (action.type) {
        case UPDATE_CHAT_SELECTED_OPTIONS: {
            const {selectedPromptId, selectedService, rememberConversation} = action.payload
            updateValueByKey(CURRENT_SELECTED_CHATBOT_OPTIONS, action.payload)
            return {
                ...state,
                selectedOptions: {
                    selectedPromptId: selectedPromptId,
                    selectedService: selectedService,
                    rememberConversation: rememberConversation
                }
            }
        }
        case ADD_CHAT_CONVERSATION: {
            const { query, response } = action.payload;
            let currentUserChatbotHistories = getValueByKey(CURRENT_USER_CHATBOT_HISTORIES)
            if (!currentUserChatbotHistories) {
                currentUserChatbotHistories = []
            }
            currentUserChatbotHistories.push({
                query: {
                    message: query.message,
                    referenceId: query.referenceId,
                    isBot: query.isBot
                },
                response: {
                    message: response.message,
                    referenceId: response.referenceId,
                    isBot: response.isBot
                }
            })
            updateValueByKey(CURRENT_USER_CHATBOT_HISTORIES, currentUserChatbotHistories)

            return {
                ...state,
                data: [...state.data, {
                    query: {
                        message: query.message,
                        referenceId: query.referenceId,
                        isBot: query.isBot
                    },
                    response: {
                        message: response.message,
                        referenceId: response.referenceId,
                        isBot: response.isBot
                    }
                }]
            }
        }
        case CLEAR_CHAT: {
            removeKey(CURRENT_USER_CHATBOT_HISTORIES)
            return {
                ...state,
                selectedOptions: state.selectedOptions,
                data: []
            }
        }
        case CLEAR_ALL_CHAT: {
            removeKey(CURRENT_SELECTED_CHATBOT_OPTIONS)
            removeKey(CURRENT_USER_CHATBOT_HISTORIES)
            return {
                ...state,
                selectedOptions: [],
                data: []
            }
        }
        default:
            return state
    }
}