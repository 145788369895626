import { Box, useTheme, Dialog, TextField, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { tokens } from '../theme'
import { useState } from 'react'
import { useAuthQueryWithQueryFunction } from '../extensions/UseAuthQuery'
import { ApiError } from '../interfaces/ErrorType'
import { createCategory } from '../actions/content'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

interface AddCategoryProps {
    open: boolean
    onClose: () => void
    mediaType: string | undefined
    mediaCategories: any[]
}

const AddCategoryModal = ({
    open,
    onClose,
    mediaType,
    mediaCategories,
}: AddCategoryProps) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [newCategoryName, setNewCategoryName] = useState<string>('')
    const [error, setError] = useState<string>('')
    const [isCreating, setIsCreating] = useState(false)

    const errors = {
        empty: "Category name can't be empty.",
        duplicate: 'Category with this name already exists.',
    }

    let mediaString: string | undefined = mediaType

    if (mediaType === 'MEDIA' || mediaType === 'BANNER') {
        mediaString = 'Image'
    } else if (mediaType === 'VIDEO') {
        mediaString = 'Video'
    } else if (mediaType === 'AUDIO') {
        mediaString = 'Audio'
    }

    const handleCategoryChange = (event: any) => {
        const cat = event?.target?.value
        setNewCategoryName(cat)
        if (cat?.length || (cat?.length === 0 && error === errors?.duplicate)) {
            setError('')
        }
    }

    const createNewCategory = useMutation<any, ApiError, any>(createCategory)

    const handleCreateCategory = () => {
        if (newCategoryName?.length === 0) {
            setError(errors?.empty)
            return
        }
        setError('')
        const newCat = mediaString?.toString() + ' > ' + newCategoryName
        const cat = mediaCategories?.filter(
            (c) => c?.name?.replaceAll(' ', '') === newCat?.replaceAll(' ', '')
        )
        if (cat?.length) {
            setError(errors?.duplicate)
        } else {
            setIsCreating(true)
            createNewCategory.mutate(newCat, {
                onSuccess: (data) => {
                    setIsCreating(false)
                    mediaCategories.push(data?.data)
                    setNewCategoryName('')
                    onClose()
                },
            })
        }
    }

    return (
        <Dialog
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    width: '450px',
                    minHeight: '200px',
                    backgroundColor:
                        theme.palette.mode === 'dark'
                            ? colors.primary[500]
                            : '',
                    padding: 3,
                },
            }}
        >
            <Box display={'flex'} flexDirection={'column'} gap={2}>
                <CloseIcon
                    sx={{
                        position: 'absolute',
                        top: '15px',
                        right: '15px',
                        cursor: 'pointer',
                    }}
                    onClick={() => onClose()}
                />

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                        marginTop: '30px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 10,
                        }}
                    >
                        <h2 style={{ width: '50px', fontWeight: 'normal' }}>
                            {mediaString}
                        </h2>
                        <ArrowForwardIosIcon sx={{ fontSize: '16px' }} />
                        <TextField
                            placeholder="Category Name"
                            id="outlined-start-adornment"
                            fullWidth
                            value={newCategoryName}
                            onChange={handleCategoryChange}
                        />
                    </div>
                    {error?.length ? (
                        <p style={{ color: '#ff2424', marginTop: '0px' }}>
                            {error}
                        </p>
                    ) : (
                        <></>
                    )}
                    <Button
                        sx={{
                            display: 'block',
                            textAlign: 'center',
                            color:
                                theme.palette.mode === 'dark'
                                    ? colors.grey[100]
                                    : colors.primary[500],

                            fontSize: '14px',
                            fontWeight: 'regular',
                        }}
                        variant="contained"
                        disabled={isCreating}
                        onClick={handleCreateCategory}
                    >
                        {isCreating ? 'Creating...' : 'Create'}
                    </Button>
                </div>
            </Box>
        </Dialog>
    )
}

export default AddCategoryModal
