import store from '../redux/store'
import { CLEAR_ALL_CHAT, UNSET_UESR } from '../redux/actionType'
import { findMyAssignee } from './user'

export const loadCurrentConfigurations = () => {
    return async () => {
        try {
            await findMyAssignee()
        } catch (error) {
        }
    }
}

export const removeAllConfigurations = () => {
    store.dispatch({
        type: UNSET_UESR,
    })
    store.dispatch({
        type: CLEAR_ALL_CHAT
    })
}
