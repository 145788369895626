import React  from 'react'
import { Box } from '@mui/material'
import Header from '../../../../components/Header'
import DataGridFilter, { SearchOptionsProp } from '../../../../components/DataGridFilter'
import AdminPromptPermissionDetail from './detail'
import { PromptPermission } from '../../../../interfaces/PromptPermissionType'
import { fetchPromptPermissions } from '../../../../actions/promptPermission'

function AdminPromptPermission() {

    const expandRow = (row: PromptPermission) => (
        <AdminPromptPermissionDetail isNew={false} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchPromptPermissions(searchOptions)
    }

    const columns = [
        {
            dataField: 'username',
            text: 'Username',
            sort: true,
        }
    ]

    return (
        <Box m='20px'>
            <Header title='Admin Prompt Permission' />

            <DataGridFilter
                keyField='id'
                useQueryKey={`admin-prompt-permission`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={2}
                expandRow={expandRow}
            />
        </Box>
    )
}

export default AdminPromptPermission