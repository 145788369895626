import SecureAxios from '../extensions/SecureAxios'

export async function loadCmsRelationships() {
    const { data } = await SecureAxios.get(`/cms/relationship`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data.data
}