import React, { useState } from 'react'
import { Box } from '@mui/material'
import {
    AuditLogTrainingData,
    AuditLogTrainingDataFilterCustomFilterOptions,
} from '../../../../../interfaces/AuditType'
import DataGridFilter, { SearchOptionsProp } from '../../../../../components/DataGridFilter'
import { fetchAuditTrainingData, generateAuditTrainingDataReport } from '../../../../../actions/audit'
import AdminPromptQueryReviewDetail from './detail'
import Header from '../../../../../components/Header'
import DatePickerExt from '../../../../../components/DatePickerExt'
import { auditPeriodOptions } from '../../../../../share/AuditConstrant'
import AutocompleteExt from '../../../../../components/Autocomplete'
import SelectExt from '../../../../../components/Select'
import { useAuthQueryWithQueryFunction } from '../../../../../extensions/UseAuthQuery'
import { ApiError } from '../../../../../interfaces/ErrorType'
import { findUsers } from '../../../../../actions/user'
import CheckboxExt from '../../../../../components/Checkbox'

function AdminPromptQueryReview() {
    const [change, setChange] = useState<string>()

    const [customSearchOptions, setCustomSearchOptions] =
        useState<AuditLogTrainingDataFilterCustomFilterOptions>({
            period: '',
            startDate: undefined,
            endDate: undefined,
            username: '',
            filteredByApprovedData: false,
        })

    const expandRow = (row: AuditLogTrainingData) => (
        <AdminPromptQueryReviewDetail wrapper={row} callback={setChange} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchAuditTrainingData(searchOptions)
    }

    const onDownloadEvent = (searchOptions: SearchOptionsProp) => {
        return generateAuditTrainingDataReport(searchOptions)
    }

    /**
     * Fetch users
     * */
    const userQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        string[]
        >('users', findUsers, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const availableUsersOptions = userQuery?.data?.map((user) => {
        return {
            value: user,
            label: user,
        }
    })

    const customSearchOptionsRenderer = () => (
        <>
            <SelectExt
                name='period'
                multiSelection={false}
                label='Period'
                selectedValue={customSearchOptions.period}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        period: value ? value : '',
                        startDate: undefined,
                        endDate: undefined
                    })
                }}
                options={auditPeriodOptions}
                isClearable={true}
            />

            {!customSearchOptions.period && (
                <>
                    <DatePickerExt
                        value={customSearchOptions.startDate}
                        onChange={(value) => {
                            console.log(value)
                            setCustomSearchOptions({
                                ...customSearchOptions,
                                startDate: value,
                            })
                        }}
                        max={customSearchOptions.endDate ? customSearchOptions.endDate : new Date().toISOString().slice(0, 10)}
                    />

                    <DatePickerExt
                        value={customSearchOptions.endDate}
                        onChange={(value) => {
                            setCustomSearchOptions({
                                ...customSearchOptions,
                                endDate: value,
                            })
                        }}
                        min={customSearchOptions.startDate}
                    />
                </>
            )}

            <AutocompleteExt
                name='username'
                multiSelection={false}
                label='Filtered by User'
                selectedValue={customSearchOptions.username}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        username: value ? value : '',
                    })
                }}
                options={availableUsersOptions}
                disableUnselectAll={false}
            />

            <CheckboxExt
                name="filteredByApprovedData"
                label="Filtered by Approved Data"
                value={customSearchOptions.filteredByApprovedData}
                onChange={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        filteredByApprovedData: value,
                    })
                }}
            />
        </>
    )

    const columns = [
        {
            dataField: 'username',
            text: 'Username',
            sort: true,
        },
        {
            dataField: 'prompt',
            text: 'Prompt',
            sort: false,
        },
        {
            dataField: 'queryAt',
            text: 'Query At',
            sort: true,
        },
        {
            dataField: 'approved',
            text: 'Approved',
            sort: false,
        },
    ]

    return (
        <Box m='20px'>
            <Header title='Admin Prompt Query Review' />

            <DataGridFilter
                keyField='id'
                useQueryKey={`audits-review`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                reportTitle={'Prompt-Query-Review-Report'}
                onDownloadEvent={onDownloadEvent}
                customSearchOptions={customSearchOptions}
                resetCustomSearchOptions={setCustomSearchOptions}
                change={change}
                customSearchOptionsRenderer={customSearchOptionsRenderer()}
                searchFilterCols={2}
                expandRow={expandRow}
            />

        </Box>
    )
}

export default AdminPromptQueryReview