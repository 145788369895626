import * as React from 'react';
import {
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

interface ImageProp {
    style: React.CSSProperties | undefined
    className?: string;
    url?: string;
    name?: string;
}

const StyledDialogContent = styled(DialogContent)`
  padding: 0;
`;

const StyledImage = styled('img')`
  width: 100%;
  height: auto;
`;

const ExpandableImage: React.FC<ImageProp> = ({
                                                  style,
                                                  className,
                                                  url,
                                                  name,
                                              }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton onClick={handleClickOpen}>
                <img
                    style={style}
                    src={url}
                    alt={name}
                    className={className}
                />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <StyledDialogContent>
                    <StyledImage src={url} alt={name} />
                </StyledDialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ExpandableImage;