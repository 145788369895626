import { AuthToken } from '../actions/auth'
import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import TinyMCE from './TinyMCE'
import TinyMceRevisionHistoryCustomPlugin from './TinyMceRevisionHistoryCustomPlugin'
import { Tabs } from 'antd'
import { Box, useTheme } from '@mui/material'
import { tokens } from '../theme'

export interface TinyMCEContainerProps {
    contentId?: string | undefined;
    user: AuthToken
    htmlValue: string;
    onChangeEvent: (valueChange: TinyMCEValueChange) => void;
    onBlurEvent?: (event: any) => void;
    error?: boolean | undefined
    helperText?: React.ReactNode
    editable?: boolean;
    onSaveKey?: string
}

export interface TinyMCEValueChange {
    htmlValue: string;
    markdownValue: string;
}

const TinyMCEContainer: FunctionComponent<TinyMCEContainerProps> = ({
                                                      contentId,
                                                      user,
                                                      htmlValue,
                                                      onChangeEvent,
                                                      onBlurEvent,
                                                      error,
                                                      helperText,
                                                      editable,
                                                      onSaveKey,
                                                  }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return (
        <Box sx={{
            '& .ant-tabs-tab': {
                color: theme.palette.mode === 'dark' ? colors.blueAccent[100] : colors.blueAccent[400]
            }
        }}>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="TinyMCE" key="1">
                    <TinyMCE
                        htmlValue={htmlValue}
                        onChangeEvent={onChangeEvent}
                        onBlurEvent={onBlurEvent}
                        error={error}
                        helperText={helperText}
                        editable={editable}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Revision" key="2">
                    <TinyMceRevisionHistoryCustomPlugin
                        contentId={contentId}
                        onRestoreVersion={(revision) => {
                            onChangeEvent({ htmlValue: revision.contentHtml, markdownValue: revision.contentMarkdown });
                            // Switch to the TinyMCE tab
                            const tabs = document.getElementsByClassName('ant-tabs-tab');
                            tabs[0].dispatchEvent(new Event('click', { bubbles: true }));
                        }}
                        onSaveKey={onSaveKey}
                    />
                </Tabs.TabPane>
            </Tabs>
        </Box>
    );
};


/**
 * Connect and retrieve the current user through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(TinyMCEContainer)