import React, { FunctionComponent, useState } from 'react'
import { Box, TextField } from '@mui/material'
import { AuditLog, AuditLogWrapper } from '../../../../interfaces/AuditType'
import TextareaAutosizeExt from '../../../../components/TextareaAutosize'


const AdminAuditDetail: FunctionComponent<AuditLogWrapper> = ({
                                                            wrapper,
                                                        }) => {
    const [auditLog] = useState<AuditLog>(wrapper!!)

    /**
     * Page containing audit log details
     */
    return (
        <Box m="20px">
            <Box
                display="grid"
                gap="30px"
            >
                <TextField
                    variant="filled"
                    type="text"
                    label="Id"
                    value={auditLog.id}
                    name="id"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Service"
                    value={auditLog.service}
                    name="service"
                />

                <TextareaAutosizeExt
                    label="Query"
                    value={auditLog.query}
                    name="query"
                    minRows={10}
                    maxRows={10}
                />

                <TextareaAutosizeExt
                    label="Prompt Request"
                    value={auditLog.promptRequest}
                    name="promptRequest"
                    minRows={10}
                    maxRows={10}
                />

                <TextareaAutosizeExt
                    label="Result"
                    value={auditLog.result}
                    name="result"
                    minRows={30}
                    maxRows={30}
                />
            </Box>
        </Box>
    )
}

export default AdminAuditDetail
