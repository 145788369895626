export const contentSubmissionStateOptions = [
    {
        value: 'DRAFT',
        label: 'Draft',
    },
    {
        value: 'REQUEST_CHANGE',
        label: 'Request Change',
    },
    {
        value: 'REQUEST_APPROVAL',
        label: 'Request Content Review',
    },
    {
        value: 'CONTENT_REVIEWED',
        label: 'Request Medical Review',
    },
    {
        value: 'MEDICAL_REVIEWED',
        label: 'Content & Medical Reviewed',
    },
]

export const adminContentSubmissionStateOptions = [
    {
        value: 'DRAFT',
        label: 'Draft',
    },
    {
        value: 'REQUEST_APPROVAL',
        label: 'Request Content Review',
    },
    {
        value: 'REQUEST_CHANGE',
        label: 'Request Change',
    },
    {
        value: 'CONTENT_REVIEWED',
        label: 'Request Medical Review',
    },
    {
        value: 'MEDICAL_REVIEWED',
        label: 'Content & Medical Reviewed',
    },
    {
        value: 'APPROVE',
        label: 'Approved',
    },
    {
        value: 'REJECT',
        label: 'Rejected',
    },
]