import TurndownService from 'turndown';
import { remark } from 'remark'
import remarkHtml from 'remark-html'
import DOMPurify from 'dompurify'

export function markdownToHtml(markdownText) {
    const file = remark().use(remarkHtml).processSync(markdownText)
    return String(file)
}

export function htmlToMarkdown(htmlText) {
    const turndownService = new TurndownService();
    let markdown = turndownService.turndown(htmlText);
    markdown = DOMPurify.sanitize(markdown);
    return markdown;
}