import React, {useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {persistTransitData} from "../../../actions/auth"; // Replace with your token retrieval logic

function AuthTransitRoute() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Extract the code and state from the URL query parameters
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');
        const state = searchParams.get('state');

        // Make an API call to retrieve the token using the code and state
        persistTransitData(code, state)
            .then(() => {
                navigate("/");
            })
            .catch((error) => {
                // Handle error
                console.error('Failed to retrieve token:', error);
                // Might required to relogin
                navigate("/");
                window.location.reload(); // Perform a hard refresh
            });
    }, [navigate, location.search]);

    return (
        <div>
            <h1>Authorization in progress...</h1>
            <p>Initiating token retrieval and redirection...</p>
        </div>
    );
}

export default AuthTransitRoute;