import React, { useEffect, useState } from 'react'
import {
    Box,
    Checkbox,
    InputLabel,
    List,
    ListItemButton,
    Typography,
} from '@mui/material'
import { HanaAssignee } from '../interfaces/AssigneeType'
import { useAuthQueryWithQueryFunction } from '../extensions/UseAuthQuery'
import { ApiError } from '../interfaces/ErrorType'
import { getRevisions } from '../actions/tinymce'
import CheckIcon from '@mui/icons-material/Check'
import { adminContentSubmissionStateOptions } from '../share/ContentSubmissionConstant'
import MarkdownDiffViewer from './MarkdownDiffViewer'
import { Button } from 'antd'
import HtmlDiffViewer from './HtmlDiffViewer'
import { markdownToHtml } from './parser/Parser'

interface TinyMceRevisionHistoryCustomPluginProps {
    contentId?: string | undefined;
    onRestoreVersion?: (revision: Revision) => void; // Handle event upon value change
    onSaveKey?: string;
}

export interface Revision {
    revisionId: string;
    modifiedAt: string;
    user?: HanaAssignee | undefined;
    contentHtml: string;
    contentMarkdown: string;
    state: string;
}

const TinyMceRevisionHistoryCustomPlugin = ({ contentId, onRestoreVersion, onSaveKey }: TinyMceRevisionHistoryCustomPluginProps) => {
    const [revisions, setRevisions] = useState<Revision[]>([]);

    const revisionQuery = useAuthQueryWithQueryFunction<
        any,
        ApiError,
        any>(
        [`revisions-query-${contentId ?? 'none'}-${onSaveKey}`, contentId],
        () => getRevisions(contentId),
        {
            refetchOnWindowFocus: false,
            retry: 1,
            enabled: false,
            onSuccess(data) {
                setRevisions(data)
            },
            onError(error) {
                console.error(error)
            },
        }
    )

    useEffect(() => {
        if (contentId) {
            revisionQuery.refetch()
        }
    }, [contentId, onSaveKey])

    if (contentId && revisionQuery.isLoading) {
        return <Box>Loading...</Box>
    }

    return (
        <RevisionHistoryModal
            revisions={revisions}
            onRestoreVersion={onRestoreVersion}
        />
    );
};

const RevisionHistoryModal = ({ revisions, onRestoreVersion }: { revisions: Revision[]; onRestoreVersion?: (revision: Revision) => void }) => {
    const [compareChange, setCompareChange] = useState<boolean>(false)
    const [selectedRevision, setSelectedRevision] = useState<Revision | undefined>();
    const [compareRevision, setCompareRevision] = useState<Revision | undefined>();

    const handleSelectRevision = (revision: Revision, revisionIndex: number) => {
        setSelectedRevision(revision);
        setCompareRevision(revisions.length > revisionIndex + 1 ? revisions[revisionIndex + 1] : revision);
    };

    useEffect(() => {
        if (revisions.length > 0) {
            setSelectedRevision(revisions[0]);
            setCompareRevision(revisions.length > 1 ? revisions[1] : revisions[0]);
        }
    }, [revisions]);

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                height: '800px',
            }}
        >
            <Box display="grid" gridTemplateColumns="2fr 16em">
                <Box display="flex">
                    {selectedRevision?.modifiedAt && (
                        <>
                            <Typography
                                variant="h2"
                                fontWeight="bold"
                                sx={{ mb: '5px', }}
                            >
                                {selectedRevision?.modifiedAt ?? ''} - {selectedRevision?.revisionId}
                            </Typography>

                            <Typography
                                variant="body2"
                                fontWeight="bold"
                                sx={{ m: '15px', }}
                            >
                                By {selectedRevision?.user?.displayName ? `${selectedRevision.user.username} (${selectedRevision.user.displayName})` : selectedRevision?.user?.username}
                            </Typography>
                        </>
                    )}
                </Box>

                <Box display="flex" justifyContent="flex-end" sx={{ marginBottom: '10px'}}>
                    <Box mt="5px">
                        <Button
                            type="primary"
                            style={{
                                marginRight: '15px',
                                backgroundColor: '#007bff', // Bootstrap primary blue
                                color: '#fff', // White text
                                border: 'none', // Remove the default border
                                borderRadius: '4px', // Add rounded corners
                                cursor: 'pointer', // Change cursor to pointer on hover
                                fontSize: '12px', // Font size
                            }}
                            onClick={() => {
                                if (onRestoreVersion && selectedRevision) {
                                    onRestoreVersion(selectedRevision);
                                }
                            }}
                        >
                            Restore
                        </Button>
                    </Box>

                    <Checkbox
                        name="compareChange"
                        value={compareChange}
                        onChange={(e) => setCompareChange(!compareChange)}
                    />

                    <InputLabel
                        style={{
                            margin: `10px 10px 0 0`,
                            color: 'black',
                            fontSize: `small`,
                        }}
                    >
                        Changes
                    </InputLabel>
                </Box>
            </Box>
            <Box sx={{ height: '750px', display: 'grid', gridTemplateColumns: '4fr 1fr', gap: 1 }}>
                {!compareChange && (
                    <Box
                        borderColor={'#e0e0e0'}
                        border={1}
                        borderRadius={1}
                        padding={1}
                        sx={{
                            overflowY: 'auto',  // Adds vertical scrollbar
                            maxHeight: '100%',  // Prevents content from overflowing
                        }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: selectedRevision?.contentHtml ?? '' }} />
                    </Box>
                )}

                {compareChange && (
                    <Box
                        sx={{
                            overflowY: 'auto',  // Adds vertical scrollbar
                            maxHeight: '100%',  // Prevents content from overflowing
                        }}
                    >
                        <CompareRevision oldRevision={compareRevision} newRevision={selectedRevision} />
                    </Box>
                )}

                <Box
                    sx={{
                        overflowY: 'auto',  // Adds vertical scrollbar
                        maxHeight: '100%',  // Prevents content from overflowing
                    }}
                >
                    <RevisionList revisions={revisions} onSelectRevision={handleSelectRevision} />
                </Box>
            </Box>
        </Box>
    );
};

const CompareRevision = ({ oldRevision, newRevision }: { oldRevision?: Revision; newRevision?: Revision }) => {

    return (
        <Box borderColor={'#e0e0e0'} border={1} borderRadius={1} padding={1}>
            <MarkdownDiffViewer
                oldMarkdown={oldRevision?.contentMarkdown ?? ''}
                newMarkdown={newRevision?.contentMarkdown ?? ''}
            />
            {/*<HtmlDiffViewer*/}
            {/*    oldHtml={markdownToHtml(oldRevision?.contentMarkdown) ?? ''}*/}
            {/*    newHtml={markdownToHtml(newRevision?.contentMarkdown) ?? ''}*/}
            {/*/>*/}
        </Box>
    );
};

const RevisionList = ({ revisions, onSelectRevision }: { revisions: Revision[]; onSelectRevision: (revision: Revision, revisionIndex: number) => void }) => {
    const [selectedRevisionIndex, setSelectedRevisionIndex] = useState<number | undefined>();

    const getDisplayState = (revision: Revision) => {
        const state = adminContentSubmissionStateOptions.find((option) => option.value === revision.state);
        return state ? state.label : revision.state;
    }

    useEffect(() => {
        if (!selectedRevisionIndex && revisions.length > 0) {
            setSelectedRevisionIndex(0);
        }
    }, [selectedRevisionIndex, revisions]);

    return (
        <Box sx={{ width: '100%', maxWidth: 360, }}>
            <Box>
                <Typography
                    variant="h4"
                    fontWeight="bold"
                    sx={{ ml: '20px', mb: '5px', }}
                >
                    Revision History
                </Typography>
            </Box>
            <List component="nav">
                {revisions.map((revision, index) => {
                    return (
                        <ListItemButton
                            sx={{ margin: '5px !important', }}
                            key={revision.revisionId}
                            onClick={() => {
                                onSelectRevision(revision, index);
                                setSelectedRevisionIndex(index);
                            }}
                            selected={index === selectedRevisionIndex}
                        >
                            <Box>
                                <Typography variant="h5">{revision.modifiedAt}</Typography>
                                {revision.user && (
                                    <Typography variant="body2">
                                        {revision.user.username}
                                        {revision.user.displayName ? ` (${revision.user.displayName})` : ''}
                                    </Typography>
                                )}
                                {revision.state && ( // Add your third line content here
                                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                                        {getDisplayState(revision)}
                                    </Typography>
                                )}
                            </Box>
                            {index === selectedRevisionIndex && (
                                <Box sx={{ marginLeft: '25px' }}>
                                    <CheckIcon />
                                </Box>
                            )}
                        </ListItemButton>
                    );
                })}
            </List>
        </Box>

    );
};

export default TinyMceRevisionHistoryCustomPlugin;