export const auditPeriodOptions = [
    {
        value: 'WEEK',
        label: 'Week',
    },
    {
        value: 'FORTNIGHT',
        label: 'Fortnight',
    },
    {
        value: 'MONTH',
        label: 'Month',
    },
]
